// components/EstimateSummary/CategorySection.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { CategoryTotal, Product, PriceTypeOption, VatRateOption } from '../../types/EstimateSummary';
import { ProductItem } from './ProductItem';
import { calculateNetPriceFromGross23, getVatRate, recalculateGrossPrice } from '../../utils/priceCalculations';

interface CategorySectionProps {
  category: CategoryTotal;
  estimateId: number;
  priceDisplayOption: 'all' | 'categoryOnly' | 'totalOnly';
  priceType: PriceTypeOption;
  vatRate: VatRateOption;
}

export const CategorySection: React.FC<CategorySectionProps> = ({
  category,
  estimateId,
  priceDisplayOption,
  priceType,
  vatRate
}) => {
  // Funkcja do grupowania produktów
  const groupAndSumProducts = (products: Product[]) => {
    const groupedProducts: { [key: string]: Product } = {};


    products.forEach(product => {
      // Klucz grupowania dla produktów z szerokością i wysokością
      const key = product.width && product.height
        ? product.name
        : `${product.name}-${product.priceOption || ''}-${product.unit}`;

      if (!groupedProducts[key]) {
        // Kopia pierwszego produktu z grupy
        groupedProducts[key] = { ...product, quantity: 0, price: 0 };
      }

      // Aktualizacja ilości i ceny
      if (product.width && product.height) {
        const area = (product.width * product.height) / 10000; // Konwersja na m²
        groupedProducts[key].quantity += area;
        groupedProducts[key].price += product.price;

        // Aktualizacja szerokości i wysokości proporcjonalnie
        groupedProducts[key].width = Math.sqrt(groupedProducts[key].quantity * 10000);
        groupedProducts[key].height = groupedProducts[key].width;
      } else {
        groupedProducts[key].quantity += product.quantity;
        groupedProducts[key].price += product.price;
      }
    });

    return Object.values(groupedProducts);
  };

  // Ikony dla różnych kategorii
  const getCategoryIcon = (category: string): string => {
    const lowerCategory = category.toLowerCase();
    if (lowerCategory.includes('meble')) return 'fas fa-couch';
    if (lowerCategory.includes('ścian')) return 'fas fa-border-all';
    if (lowerCategory.includes('podłog')) return 'fas fa-th';
    if (lowerCategory.includes('drzw')) return 'fas fa-door-open';
    if (lowerCategory.includes('okn')) return 'fas fa-window-restore';
    if (lowerCategory.includes('łazien')) return 'fas fa-bath';
    if (lowerCategory.includes('kuchn')) return 'fas fa-utensils';
    if (lowerCategory.includes('elektr')) return 'fas fa-plug';
    if (lowerCategory.includes('hydraul')) return 'fas fa-faucet';
    if (lowerCategory.includes('ogród')) return 'fas fa-seedling';
    return 'fas fa-clipboard-list';
  };

  // Funkcja do generowania czytelnego identyfikatora dla url
  const generateUrlCategoryId = (categoryName: string): string => {
    if (!categoryName) return '';

    // Jeśli mamy już catChild, używamy go
    if (category.catChild && typeof category.catChild === 'string') {
      return category.catChild;
    }

    // W przeciwnym razie wygeneruj ID z nazwy kategorii
    return categoryName
      .toLowerCase()
      .replace(/\s+/g, '-')       // Zamieniamy spacje na myślniki
      .replace(/[ąćęłńóśźż]/g, c => { // Zamieniamy polskie znaki
        const chars: Record<string, string> = {
          'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n',
          'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z'
        };
        return chars[c] || c;
      })
      .replace(/[^a-z0-9-]/g, ''); // Usuwamy wszystkie znaki inne niż litery, cyfry i myślniki
  };

  const groupedProducts = groupAndSumProducts(category.products);
  const categoryIcon = getCategoryIcon(category.category);
  const vat = getVatRate(vatRate);
  // Obliczamy cenę brutto z odpowiednią stawką VAT
  const grossTotal = vatRate === 'vat23'
    ? category.total  // Jeśli wybrano 23%, używamy oryginalnej ceny
    : recalculateGrossPrice(category.total, vat);  // W przeciwnym razie przeliczamy
  const netTotal = calculateNetPriceFromGross23(category.total);
  const categoryUrlId = generateUrlCategoryId(category.category);

  return (
    <div className="category-section" style={{
      border: '1px solid #ddd',
      borderRadius: '4px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f5f5f5',
      overflow: 'hidden'
    }}>
      <div className="category-header p-2"
        style={{ backgroundColor: '#e8e8e8', borderLeft: '4px solid #3273dc' }}>
        <p className="is-flex is-justify-content-space-between is-size-6 has-text-weight-medium mb-0">
          <span className="is-flex is-align-items-center">
            <span className="icon mr-2 has-text-info">
              <i className={categoryIcon}></i>
            </span>
            <Link to={`/estimate-furniture/${estimateId}/${categoryUrlId}`}>
              {category.category}
            </Link>
          </span>
          {(priceDisplayOption === 'all' || priceDisplayOption === 'categoryOnly') && (
            <span className="is-flex is-align-items-center">
              {priceType === 'gross' && (
                <span className="has-text-weight-semibold">{grossTotal.toFixed(0)} zł</span>
              )}
              {priceType === 'net' && (
                <span className="has-text-weight-semibold">{netTotal.toFixed(0)} zł</span>
              )}
              {priceType === 'both' && (
                <div className="is-flex is-flex-direction-column has-text-right">
                  <span className="has-text-weight-semibold">{grossTotal.toFixed(0)} zł brutto</span>
                  <span className="is-size-7">{netTotal.toFixed(0)} zł netto</span>
                </div>
              )}
              <span className="icon ml-1 has-text-grey">
                <i className="fas fa-coins"></i>
              </span>
            </span>
          )}
        </p>
      </div>

      <div className="product-list flex-grow-1" style={{ backgroundColor: '#f5f5f5' }}>
        {groupedProducts.map((product, index) => (
          <ProductItem
            key={index}
            product={product}
            index={index}
            priceType={priceType}
            showPrice={priceDisplayOption === 'all'}
            vatRate={vatRate}
          />
        ))}      </div>
    </div>
  );
};
