// components/EstimateSummary/templates/ProfessionalTemplate.tsx
import React, { forwardRef } from 'react';
import { CategoryTotal, EstimateData, PriceDisplayOption, PriceTypeOption, UserCompany, Product, VatRateOption } from '../../../types/EstimateSummary';
import { getConditionalUrl } from '../../../utils/cdnHelper';
import logoImage from '../../../assets/images/logo.png';
import head_image from '../../../assets/images/wycena.jpg';
import { calculateNetPrice, calculateNetPriceFromGross23, formatPriceInt, getVatRate, recalculateGrossPrice } from '../../../utils/priceCalculations';

interface ProfessionalTemplateProps {
  estimateData: EstimateData;
  userInfo: UserCompany | null;
  categorySummaries: CategoryTotal[];
  totalPrice: number;
  discountedPrice: number;
  priceDisplayOption: PriceDisplayOption;
  priceType: PriceTypeOption;
  vatRate: VatRateOption;
}

export const ProfessionalTemplate = forwardRef<HTMLDivElement, ProfessionalTemplateProps>(({
  estimateData,
  userInfo,
  categorySummaries,
  totalPrice,
  discountedPrice,
  priceDisplayOption,
  priceType,
  vatRate
}, ref) => {
  const { name, description, createdAt, discount } = estimateData;

  // Funkcja do grupowania produktów (identyczna jak w CategorySection)
  const groupAndSumProducts = (products: Product[]) => {
    const groupedProducts: { [key: string]: Product } = {};

    products.forEach(product => {
      // Klucz grupowania dla produktów z szerokością i wysokością
      const key = product.width && product.height
        ? product.name
        : `${product.name}-${product.priceOption || ''}-${product.unit}`;

      if (!groupedProducts[key]) {
        // Kopia pierwszego produktu z grupy
        groupedProducts[key] = { ...product, quantity: 0, price: 0 };
      }

      // Aktualizacja ilości i ceny
      if (product.width && product.height) {
        const area = (product.width * product.height) / 10000; // Konwersja na m²
        groupedProducts[key].quantity += area;
        groupedProducts[key].price += product.price;

        // Aktualizacja szerokości i wysokości proporcjonalnie
        groupedProducts[key].width = Math.sqrt(groupedProducts[key].quantity * 10000);
        groupedProducts[key].height = groupedProducts[key].width;
      } else {
        groupedProducts[key].quantity += product.quantity;
        groupedProducts[key].price += product.price;
      }
    });

    return Object.values(groupedProducts);
  };

  // Obliczenia cen netto
  const vat = getVatRate(vatRate);
  const netTotalPrice = calculateNetPriceFromGross23(totalPrice);
  const netDiscountedPrice = calculateNetPriceFromGross23(discountedPrice);

  // Ceny brutto z wybraną stawką VAT
  const grossTotalPrice = vatRate === 'vat23'
    ? totalPrice
    : recalculateGrossPrice(totalPrice, vat);
  const grossDiscountedPrice = vatRate === 'vat23'
    ? discountedPrice
    : recalculateGrossPrice(discountedPrice, vat);

  // Formatowanie daty
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Format YYYY-MM-DD
  };

  // Generowanie numeru wyceny w formacie MM-DD/YYYY
  const estimateNumber = (() => {
    const date = new Date(createdAt);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}/${year}`;
  })();

  const formatQuantity = (product: CategoryTotal['products'][0]): string => {
    if (product.width && product.height) {
      const area = (product.width * product.height) / 10000;
      return `${area.toFixed(2)} m²`;
    }
    if (product.unit === 'szt') {
      return `${product.quantity.toFixed(0)} ${product.unit}`;
    }
    return `${product.quantity.toFixed(2)} ${product.unit}`;
  };

  return (
    <div className="column" ref={ref}>
      <div className="professional-template" style={{
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        padding: '0',
        overflow: 'hidden'
      }}>
        {/* Górna belka z czerwonym obramowaniem */}
        <div style={{
          borderTop: '3px solid #cc0000',
          padding: '0 1px'
        }}></div>

        <div>
          <img
            src={head_image}
            alt="GoEstima"
            style={{ maxWidth: '100%' }}
          />
        </div>


        {/* Nagłówek z logo */}
        <div style={{
          display: 'flex',
          padding: '20px',
          borderBottom: '1px solid #eee'
        }}>
          <div style={{
            width: '35%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            backgroundColor: '#f8f8f8',
            border: '1px solid #eee',
            margin: '0 20px 0 0'
          }}>
            {userInfo?.company_logo ? (
              <img
                src={getConditionalUrl(userInfo.company_logo)}
                alt="Logo firmy"
                style={{ maxWidth: '100%', maxHeight: '120px' }}
              />
            ) : (
              <img
                src={logoImage}
                alt="GoEstima"
                style={{ maxWidth: '100%', maxHeight: '120px' }}
              />
            )}
          </div>
          <div style={{ width: '65%' }}>
            <h2 style={{
              fontSize: '22px',
              margin: '0 0 10px 0',
              color: '#333',
              textAlign: 'right',
              fontWeight: 'bold',
              textTransform: 'uppercase'
            }}>
              PRODUCENT<br />MEBLI<br />NA WYMIAR
            </h2>
            <div style={{ textAlign: 'right' }}>
              {userInfo?.company_name && <p style={{ margin: '3px 0', fontWeight: 'bold' }}>{userInfo.company_name}</p>}
              {userInfo?.address_line && <p style={{ margin: '3px 0' }}>{userInfo.address_line}</p>}
              {(userInfo?.zipcode_postal || userInfo?.city) && (
                <p style={{ margin: '3px 0' }}>
                  {userInfo.zipcode_postal && `${userInfo.zipcode_postal} `}
                  {userInfo.city}
                </p>
              )}
              {userInfo?.company_nip && <p style={{ margin: '3px 0' }}>NIP: {userInfo.company_nip}</p>}
              {userInfo?.phone_number && <p style={{ margin: '3px 0' }}>tel: {userInfo.phone_number}</p>}
              {/* {userInfo?.email && (
                <>
                  <p style={{ margin: '3px 0' }}>biuro@moja_domena.pl</p>
                  <p style={{ margin: '3px 0' }}>www.moja_domena.pl</p>
                </>
              )} */}
            </div>
          </div>
        </div>

        {/* Skośny banner */}
        <div style={{
          position: 'relative',
          height: '80px',
          overflow: 'hidden',
          backgroundColor: '#222',
          marginBottom: '30px'
        }}>
          <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            height: '100%',
            backgroundImage: 'linear-gradient(to right, #111, #444)',
            display: 'flex',
            alignItems: 'center',
            padding: '0 20px'
          }}>
            <h1 style={{
              color: 'white',
              margin: '0',
              fontSize: '28px',
              fontWeight: 'normal',
              textTransform: 'uppercase'
            }}>
              OFERTA MEBLI NA WYMIAR
            </h1>
          </div>
        </div>

        {/* Dane oferty */}
        <div style={{
          display: 'flex',
          padding: '0 20px 20px',
          borderBottom: '1px solid #eee',
          margin: '0 0 20px'
        }}>
          <div style={{ width: '50%' }}>
            <h3 style={{
              color: '#cc0000',
              fontSize: '18px',
              margin: '0 0 15px 0',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
              DANE OFERTY
            </h3>
            <div style={{
              display: 'flex',
              borderBottom: '1px dashed #eee',
              padding: '5px 0'
            }}>
              <div style={{
                width: '140px',
                textAlign: 'right',
                paddingRight: '15px',
                fontWeight: 'bold'
              }}>
                NR
              </div>
              <div>{estimateNumber}</div>
            </div>
            <div style={{
              display: 'flex',
              borderBottom: '1px dashed #eee',
              padding: '5px 0'
            }}>
              <div style={{
                width: '140px',
                textAlign: 'right',
                paddingRight: '15px',
                fontWeight: 'bold'
              }}>
                Z DNIA
              </div>
              <div>{formatDate(createdAt)}</div>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <h3 style={{
              color: '#cc0000',
              fontSize: '18px',
              margin: '0 0 15px 0',
              textAlign: 'center',
              textTransform: 'uppercase'
            }}>
              DANE ZAMAWIAJĄCEGO
            </h3>
            <div style={{
              display: 'flex',
              borderBottom: '1px dashed #eee',
              padding: '5px 0'
            }}>
              <div style={{
                width: '140px',
                textAlign: 'right',
                paddingRight: '15px',
                fontWeight: 'bold'
              }}>
                IMIĘ I NAZWISKO
              </div>
              <div>{name}</div>
            </div>
            <div style={{
              display: 'flex',
              borderBottom: '1px dashed #eee',
              padding: '5px 0'
            }}>
              <div style={{
                width: '140px',
                textAlign: 'right',
                paddingRight: '15px',
                fontWeight: 'bold'
              }}>
                ADRES
              </div>
              <div>{description}</div>
            </div>
          </div>
        </div>

        {/* Produkty wyceny */}
        <div style={{ padding: '0 20px 20px' }}>
          {categorySummaries.map((category) => (
            <div key={category.category} style={{
              marginBottom: '25px',
              border: '1px solid #eee',
              borderRadius: '4px',
              overflow: 'hidden'
            }}>
              <div style={{
                backgroundColor: '#f5f5f5',
                padding: '10px 15px',
                borderBottom: '1px solid #eee'
              }}>
                <h3 style={{
                  margin: '0',
                  fontSize: '18px',
                  color: '#333',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <span>{category.category}</span>
                  {(priceDisplayOption === 'all' || priceDisplayOption === 'categoryOnly') && (
                    <span style={{
                      fontSize: '16px',
                      backgroundColor: '#3273dc',
                      color: 'white',
                      padding: '2px 10px',
                      borderRadius: '3px'
                    }}>
                      {priceType === 'gross' && `${formatPriceInt(vatRate === 'vat23' ? category.total : recalculateGrossPrice(category.total, vat))} zł`}
                      {priceType === 'net' && `${formatPriceInt(calculateNetPrice(category.total))} zł netto`}
                      {priceType === 'both' && `${formatPriceInt(vatRate === 'vat23' ? category.total : recalculateGrossPrice(category.total, vat))} zł / ${formatPriceInt(calculateNetPriceFromGross23(category.total))} zł netto`}
                    </span>
                  )}
                </h3>
              </div>
              <div style={{ padding: '0' }}>
                {/* Grupowanie produktów */}
                {groupAndSumProducts(category.products).map((product, prodIndex) => (
                  <div key={prodIndex} style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 15px',
                    borderBottom: prodIndex < category.products.length - 1 ? '1px solid #f0f0f0' : 'none',
                    backgroundColor: prodIndex % 2 === 0 ? '#fafafa' : '#fff'
                  }}>
                    <div style={{
                      width: '60px',
                      height: '60px',
                      backgroundColor: '#f0f0f0',
                      marginRight: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #e0e0e0'
                    }} className="product-image-container">
                      {product.imageUrl ? (
                        <img
                          src={getConditionalUrl(product.imageUrl)}
                          alt={product.name}
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      ) : (
                        <i className="fas fa-cube" style={{ fontSize: '24px', color: '#ccc' }}></i>
                      )}
                    </div>
                    <div style={{ flex: '1' }}>
                      <div style={{ fontWeight: 'bold', marginBottom: '3px' }}>
                        {product.name}
                        {product.priceOption && (
                          <span style={{
                            fontWeight: 'normal',
                            marginLeft: '5px',
                            color: '#777',
                            fontSize: '14px'
                          }}>
                            ({product.priceOption})
                          </span>
                        )}
                        {product.isDeleted && (
                          <span style={{
                            color: '#cc0000',
                            marginLeft: '10px',
                            fontSize: '14px'
                          }}>
                            <i className="fas fa-exclamation-triangle" style={{ marginRight: '5px' }}></i>
                            archiwalny
                          </span>
                        )}
                      </div>
                      <div style={{ fontSize: '14px', color: '#777' }}>
                        {formatQuantity(product)}
                      </div>
                    </div>
                    {priceDisplayOption === 'all' && (
                      <div style={{
                        minWidth: '100px',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}>
                        {priceType === 'gross' && `${formatPriceInt(vatRate === 'vat23' ? product.price : recalculateGrossPrice(product.price, vat))} zł`}
                        {priceType === 'net' && `${formatPriceInt(calculateNetPrice(product.price))} zł`}
                        {priceType === 'both' && (
                          <div>
                            <div>{formatPriceInt(vatRate === 'vat23' ? product.price : recalculateGrossPrice(product.price, vat))} zł</div>
                            <div style={{ fontSize: '13px', color: '#777' }}>
                              {formatPriceInt(calculateNetPriceFromGross23(product.price))} zł netto
                            </div>
                          </div>
                        )}

                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Miejsce na zdjęcie */}
        {/* <div style={{
          margin: '0 20px 30px',
          height: '300px',
          backgroundColor: '#f9f9f9',
          border: '1px dashed #ddd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <div style={{
            color: '#aaa',
            textAlign: 'center'
          }}>
            <i className="fas fa-image" style={{ fontSize: '32px', marginBottom: '10px' }}></i>
            <p>Miejsce na zdjęcie projektu</p>
          </div>
        </div> */}

        {/* Podsumowanie cenowe */}
        <div style={{
          padding: '20px',
          backgroundColor: '#f5f5f5',
          borderTop: '1px solid #ddd'
        }}>
          <h3 style={{
            margin: '0 0 15px 0',
            textAlign: 'center',
            fontSize: '18px',
            textTransform: 'uppercase'
          }}>
            CENA ZA CAŁOŚĆ:
          </h3>
          <div style={{
            display: 'flex',
            borderBottom: '1px solid #eee'
          }}>
            {/* Warunkowe wyświetlanie kolumny NETTO */}
            {priceType !== 'gross' && (
              <div style={{
                width: priceType === 'net' ? '100%' : '50%',
                padding: '10px 15px',
                textAlign: 'center',
                fontWeight: 'bold',
                borderRight: priceType === 'both' ? '1px solid #eee' : 'none'
              }}>
                NETTO
              </div>
            )}

            {/* Warunkowe wyświetlanie kolumny BRUTTO */}
            {priceType !== 'net' && (
              <div style={{
                width: priceType === 'gross' ? '100%' : '50%',
                padding: '10px 15px',
                textAlign: 'center',
                fontWeight: 'bold'
              }}>
                BRUTTO ( VAT {vatRate === 'vat23' ? '23%' : '8%'} )
              </div>
            )}
          </div>

          {/* Teraz druga część tabeli z wartościami */}
          <div style={{ display: 'flex' }}>
            {/* Warunkowe wyświetlanie wartości NETTO */}
            {priceType !== 'gross' && (
              <div style={{
                width: priceType === 'net' ? '100%' : '50%',
                padding: '15px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                color: '#cc0000',
                borderRight: priceType === 'both' ? '1px solid #eee' : 'none'
              }}>
                {discount > 0
                  ? formatPriceInt(netDiscountedPrice)
                  : formatPriceInt(netTotalPrice)} zł
              </div>
            )}

            {/* Warunkowe wyświetlanie wartości BRUTTO */}
            {priceType !== 'net' && (
              <div style={{
                width: priceType === 'gross' ? '100%' : '50%',
                padding: '15px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '18px',
                color: '#cc0000'
              }}>
                {discount > 0
                  ? formatPriceInt(grossDiscountedPrice)
                  : formatPriceInt(grossTotalPrice)} zł
              </div>
            )}
          </div>

          {discount > 0 && (
            <div style={{
              textAlign: 'center',
              color: '#cc0000',
              margin: '0 0 15px'
            }}>
              <i className="fas fa-tag" style={{ marginRight: '5px' }}></i>
              Rabat {discount}% (oszczędzasz: {formatPriceInt(grossTotalPrice - grossDiscountedPrice)} zł)
            </div>
          )}

          <div style={{
            textAlign: 'center',
            color: '#777',
            fontSize: '14px',
            margin: '0 0 5px'
          }}>
            --- WYCENA WAŻNA 21 DNI ---
            <div style={{ marginTop: '5px' }}>
              * Wszystkie ceny {priceType === 'net' ? 'są cenami netto' : priceType === 'both' ? `zawierają wartości netto i brutto z ${vatRate === 'vat23' ? '23%' : '8%'} VAT` : `zawierają podatek VAT ${vatRate === 'vat23' ? '23%' : '8%'}`}
            </div>
          </div>
        </div>

        {/* Stopka */}
        <div style={{
          padding: '15px 20px',
          borderTop: '1px solid #ddd',
          backgroundColor: '#222',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div style={{
            backgroundImage: 'linear-gradient(to right, #222, #444)',
            padding: '10px 20px',
            borderRadius: '4px'
          }}>

            <div style={{ fontSize: '14px' }}>
              <div>
                {userInfo?.company_name && <div style={{ marginBottom: '5px' }}>{userInfo.company_name}</div>}
                {userInfo?.phone_number && (
                  <div style={{ marginBottom: '5px' }}>
                    <i className="fas fa-phone" style={{ marginRight: '5px' }}></i>
                    {userInfo.phone_number}
                  </div>
                )}
                {userInfo?.email && (
                  <div>
                    <i className="fas fa-envelope" style={{ marginRight: '5px' }}></i>
                    {userInfo.email}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{
            fontSize: '12px',
            textAlign: 'right'
          }}>
            <div>Wydrukowano: {new Date().toLocaleDateString()}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

ProfessionalTemplate.displayName = 'ProfessionalTemplate';
