// components/EstimateSummary/ProductItem.tsx
import React from 'react';
import { Product, VatRateOption } from '../../types/EstimateSummary';
import { calculateNetPriceFromGross23, getVatRate, recalculateGrossPrice } from "../../utils/priceCalculations";

interface ProductItemProps {
  product: Product;
  index: number;
  priceType: 'gross' | 'net' | 'both';
  showPrice: boolean;
  vatRate: VatRateOption;
}

export const ProductItem: React.FC<ProductItemProps> = ({
  product,
  index,
  priceType,
  showPrice,
  vatRate
}) => {
  const textColorClass = product.isDeleted ? 'has-text-danger' : '';
  const rowBackgroundClass = index % 2 === 0 ? 'has-background-white-ter' : 'has-background-white-bis';

  const formatQuantity = (product: Product): string => {
    if (product.width && product.height) {
      const area = (product.width * product.height) / 10000;
      return `${area.toFixed(2)} m²`;
    }
    if (product.unit === 'szt') {
      return `${product.quantity.toFixed(0)} ${product.unit}`;
    }
    return `${product.quantity.toFixed(2)} ${product.unit}`;
  };

  // Obliczanie ceny netto (przy założeniu, że price to cena brutto)
  const netPrice = calculateNetPriceFromGross23(product.price);
  // Obliczanie ceny brutto z wybraną stawką VAT
  const vat = getVatRate(vatRate);
  const grossPrice = vatRate === 'vat23'
    ? product.price  // Jeśli wybrano 23%, używamy oryginalnej ceny
    : recalculateGrossPrice(product.price, vat);  // W przeciwnym razie przeliczamy


  return (
    <div className={`product-item is-flex is-justify-content-space-between py-1 px-2 ${rowBackgroundClass} ${textColorClass}`}>
      <div className="product-info is-flex is-align-items-center">
        <span className="icon mr-2 has-text-info">
          <i className="fas fa-cube"></i>
        </span>
        <span className="product-name is-size-6">{product.name}</span>
        {product.priceOption && (
          <span className="product-option is-size-6 has-text-grey ml-1">
            ({product.priceOption})
          </span>
        )}
        {product.isDeleted && (
          <span className="is-size-6 has-text-danger ml-1">
            <i className="fas fa-exclamation-triangle mr-1"></i>
            archiwalny
          </span>
        )}
      </div>
      <div className="is-flex is-align-items-center">
        {showPrice && (
          <div className="product-price mr-3 has-text-right">
            {priceType === 'gross' && (
              <div className="is-flex is-flex-direction-column">
                <span className="is-size-6">{grossPrice.toFixed(0)} zł</span>
              </div>
            )}
            {priceType === 'net' && (
              <span className="is-size-6">{netPrice.toFixed(0)} zł</span>
            )}  
            {priceType === 'both' && (
              <div className="is-flex is-flex-direction-column has-text-right">
                <span className="is-size-6">{grossPrice.toFixed(0)} zł brutto</span>
                <span className="is-size-7 has-text-grey">{netPrice.toFixed(0)} zł netto</span>
              </div>
            )}
          </div>
        )}
        <div className="product-quantity is-size-6 has-text-right">
          <span className="icon-text">
            <span>{formatQuantity(product)}</span>
            <span className="icon ml-1 has-text-grey">
              <i className="fas fa-ruler-combined"></i>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};