// components/EstimateSummary/templates/DefaultTemplate.tsx
import React, { forwardRef } from 'react';
import { CategoryTotal, EstimateData, PriceDisplayOption, PriceTypeOption, UserCompany, VatRateOption } from '../../../types/EstimateSummary';
import { SummaryHeader } from '../SummaryHeader';
import { SummaryDetails } from '../SummaryDetails';

interface DefaultTemplateProps {
  estimateData: EstimateData;
  userInfo: UserCompany | null;
  categorySummaries: CategoryTotal[];
  totalPrice: number;
  discountedPrice: number;
  priceDisplayOption: PriceDisplayOption;
  priceType: PriceTypeOption;
  vatRate: VatRateOption;
}

export const DefaultTemplate = forwardRef<HTMLDivElement, DefaultTemplateProps>(({
  estimateData,
  userInfo,
  categorySummaries,
  totalPrice,
  discountedPrice,
  priceDisplayOption,
  priceType,
  vatRate
}, ref) => {
  const { name, description, createdAt, updatedAt, discount } = estimateData;

  // Debug: Log the props to understand the data
  console.log('DefaultTemplate Props:', {
    estimateData,
    categorySummaries,
    totalPrice,
    discountedPrice,
    priceDisplayOption,
    priceType,
    discount
  });

  return (
    <div className="column summary-container" ref={ref}>
      <div className="summary-content-web">
        <div className="columns mb-4">
          <div className="column">
            <h2 className="summary-title">{name}</h2>
            <p>{description}</p>
          </div>
          <div className="column has-text-right">
            <p><strong>Utworzona:</strong> {new Date(createdAt).toLocaleDateString()}</p>
          </div>
        </div>
        <SummaryDetails
          categorySummaries={categorySummaries}
          estimateId={estimateData.id}
          totalPrice={totalPrice}
          discount={discount}
          discountedPrice={discountedPrice}
          priceDisplayOption={priceDisplayOption}
          priceType={priceType}
          vatRate={vatRate}
        />
      </div>
      <div className="summary-content-print">
        <SummaryHeader
          estimateName={name}
          estimateDescription={description}
          createdAt={createdAt}
          updatedAt={updatedAt}
          userInfo={userInfo}
        />
        <SummaryDetails
          categorySummaries={categorySummaries}
          estimateId={estimateData.id}
          totalPrice={totalPrice}
          discount={discount}
          discountedPrice={discountedPrice}
          priceDisplayOption={priceDisplayOption}
          priceType={priceType}
          vatRate={vatRate}
        />
      </div>
    </div>
  );
});

DefaultTemplate.displayName = 'DefaultTemplate';