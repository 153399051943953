// HomePage.tsx
import React from "react";
import "./Changelog.scss";
import { currentVersion } from "../../utils/currentVersion";
import { Helmet } from "react-helmet";

export const Changelog: React.FC = () => {

  return (
    <div className="terms-of-service">
      <Helmet>
        <title>Historia aktualizacji | GoEstima</title>
        <meta name="description" content="Lista kolejnych aktualizacji kalkulatora mebli kuchennych | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/changelog" />
      </Helmet>
      <p className="has-text-info has-text-centered is-size-3">
        {currentVersion}
      </p>
      <p className="has-text-info has-text-centered">
        <span className="icon">
          <i className="fa-regular fa-clock"></i>
        </span>
        <span>
          Aktualna wersja aplikacji GoEstima
        </span>
      </p>

      <h1 className="pt-5">Historia aktualizacji</h1>

      <section>
        <h2>15.03.2025 | v1.3
        </h2>
        <ul>
          <li>
            Dodano szablony dla wycen.
          </li>
          <li>
            Dodano wyświetlanie cen nettu/brutto.
          </li>
        </ul>
      </section>

      <section>
        <h2>12.02.2025 | v1.2.1</h2>
        <ul>
          <li>
            Dodane nowe wzory dokumentów dla stolarzy.
          </li>
          <li>
            Poprawiono layout przycisków.
          </li>
        </ul>
      </section>

      <section>
        <h2>10.02.2025 | v1.2</h2>
        <ul>
          <li>
            Wprowadzono wersję Premium aplikacji oraz płatności z Przelewy24.
          </li>
        </ul>
      </section>

      <section>
        <h2>15.01.2025 | v1.0</h2>
        <ul>
          <li>
            Stabilna wersja aplikacji.
          </li>
        </ul>
      </section>

      <section>
        <h2>20.11.2024 | v0.2.1</h2>
        <ul>
          <li>
            Dodano moduł ZAMÓWIEŃ (beta).
          </li>
          <li>
            Naprawiono błąd, który powodował zjadanie znaków przy szybkim wpisywaniu wartości produktów.
          </li>
          <li>
            Kosmetyczne poprawki UI.
          </li>
        </ul>
      </section>

      <section>
        <h2>02.10.2024 | v0.1.12</h2>
        <ul>
          <li>
            Udoskonalono podawanie wartości podczas tworzenia wyceny, dodatkowo od teraz aby zatwierdzić wpisaną wartość można użyć klawisza Enter.
          </li>
          <li>
            <span>
              Dodano <strong>podpowiedzi</strong> czyli krótkie informacje widoczne po najechaniu kursorem na ikonę
            </span>
            <span className="icon">
              <i className="fa-regular fa-circle-question"></i>
            </span>
          </li>
          <li>
            Zmiana strony <strong>Ustawienia</strong> na której od teraz można wyłączyć lub ponownie włączyć PODPOWIEDZI.
          </li>
        </ul>
      </section>

      <section>
        <h2>30.09.2024 | v0.1.11</h2>
        <ul>
          <li>
            Udoskonalono stronę główną dla zalogowanych użytkowników. Od teraz od razu po zalogowaniu się widoczna jest skrócona lista ostatnich wycen..
          </li>
          <li>
            Poprawki responsywności.
          </li>
        </ul>
      </section>

      <section>
        <h2>26.09.2024 | v0.1.10</h2>
        <ul>
          <li>
            Ogólne poprawki błędów.
          </li>
          <li>
            Dodano sekcję POMOC.
          </li>
        </ul>
      </section>

      <section>
        <h2>24.09.2024 | v0.1.9</h2>
        <ul>
          <li>
            Ulepszono wyszukiwarkę produktów i wycen. Teraz wystarczy wpisać tylko początek jednego słowa lub początki kilku słów. Np. tworząc wycenę, w zakładce ZAWIASY chcemy wyświetlić tylko zawiasy BLUM nakładane, wystarczy wpisać w wyszukiwarce <span className="has-text-weight-bold">bl na</span>.
          </li>
          <li>
            Naprawiono błąd, który powodował usuwanie informacji o firmie (nazwa, NIP, adres, itd) oraz customowe logo.
          </li>
          <li>
            Poszerzono bazę produktów o kolejne kilka pozycji.
          </li>
        </ul>
      </section>

      <section>
        <h2>22.09.2024 | v0.1.8</h2>
        <ul>
          <li>
            Dodano nowe produkty.
          </li>
          <li>
            Poprawiono wygląd podsumowania wyceny.
          </li>
        </ul>
      </section>

      <section>
        <h2>13.09.2024 | v0.1.7</h2>
        <ul>
          <li>
            Poprawiono wygląd listy wycen.
          </li>
          <li>
            Poprawiono szybkość ładowania obrazków w sekcji EDYCJA PRODUKTÓW.
          </li>
          <li>
            Ogólne poprawki usprawniające.
          </li>
        </ul>
      </section>

      <section>
        <h2>13.09.2024 | v0.1.6</h2>
        <ul>
          <li>
            Dodano wyszukiwarkę produktów w każdej kategorii.
          </li>
        </ul>
      </section>

      <section>
        <h2>12.09.2024 | v0.1.5</h2>
        <ul>
          <li>
            WAŻNE: od teraz aplikacja w zakładce FRONTY wyświetla powierzchnię dodanych korpusów w zakładce KORPUSY.
          </li>
        </ul>
      </section>

      <section>
        <h2>10.09.2024 | v0.1.4</h2>
        <ul>
          <li>
            Dodano możliwość zablokowania wyceny, aby przypadkowo nie zmienić lub nie usunąć wyceny, która jest ważna / ostateczna.
          </li>
          <li>
            Dodano sekcję artykułów o meblarstwie (link w stopce).
          </li>
        </ul>
      </section>

      <section>
        <h2>04.09.2024 | v0.1.3</h2>
        <ul>
          <li>
            Dodanie danych firmy do wydruku wyceny.
          </li>
          <li>
            Kosmetyczne poprawki layoutu.
          </li>
          <li>
            Poprawiono szybkość ładowania obrazków produktów.
          </li>
          <li>
            Poprawiono wyświetlanie przycisków RABAT, DRUKUJ WYCENĘ, ZAPISZ PDF na urządzeniach mobilnych.
          </li>
        </ul>
      </section>

      <section>
        <h2>28.08.2024 | v0.1.2</h2>
        <ul>
          <li>
            Dodano opcję rabatu w podsumowaniu wyceny.
          </li>
          <li>
            Dodano opcję wyświetlania cen w podsumowaniu: tylko suma, suma i podsumy kategorii, wszystkie ceny jednostkowe.
          </li>
        </ul>
      </section>
    </div>
  );
};
