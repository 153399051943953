import React from 'react';
import {
  PriceDisplayOption,
  PriceTypeOption,
  TemplateOption,
  VatRateOption
} from '../../types/EstimateSummary';

interface ControlPanelProps {
  priceDisplayOption: PriceDisplayOption;
  setPriceDisplayOption: (option: PriceDisplayOption) => void;
  priceType: PriceTypeOption;
  setPriceType: (type: PriceTypeOption) => void;
  vatRate: VatRateOption;
  setVatRate: (rate: VatRateOption) => void;
  template: TemplateOption;
  setTemplate: (template: TemplateOption) => void;
  discount: number;
  discountInput: string;
  setDiscountInput: (input: string) => void;
  showDiscountInput: boolean;
  setShowDiscountInput: (show: boolean) => void;
  isUpdatingDiscount: boolean;
  handleDiscountSubmit: () => void;
  handleDiscountInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handlePrint: () => void;
  handleSavePDF: () => void;
}

export const ControlPanel: React.FC<ControlPanelProps> = ({
  priceDisplayOption,
  setPriceDisplayOption,
  priceType,
  setPriceType,
  vatRate,
  setVatRate,
  template,
  setTemplate,
  discount,
  discountInput,
  setDiscountInput,
  showDiscountInput,
  setShowDiscountInput,
  isUpdatingDiscount,
  handleDiscountSubmit,
  handleDiscountInputChange,
  handleKeyDown,
  handlePrint,
  handleSavePDF
}) => {
  const templateOptions: {
    value: TemplateOption;
    icon: string;
    label: string;
    description: string;
  }[] = [
    {
      value: 'default',
      icon: 'fas fa-list',
      label: 'Domyślny',
      description: 'Prosty, czytelny szablon'
    },
    {
      value: 'professional',
      icon: 'fas fa-briefcase',
      label: 'Profesjonalny',
      description: 'Elegancki, biznesowy wygląd'
    },
    {
      value: 'simple',
      icon: 'fas fa-adjust',
      label: 'Prosty',
      description: 'Minimalistyczny projekt'
    }
  ];

  const priceDisplayOptions: {
    value: PriceDisplayOption;
    icon: string;
    label: string;
    description: string;
  }[] = [
    {
      value: 'totalOnly',
      icon: 'fas fa-calculator',
      label: 'Suma całkowita',
      description: 'Tylko całkowita kwota'
    },
    {
      value: 'categoryOnly',
      icon: 'fas fa-layer-group',
      label: 'Sumy kategorii',
      description: 'Podsumowanie kategorii'
    },
    {
      value: 'all',
      icon: 'fas fa-list-ol',
      label: 'Wszystkie ceny',
      description: 'Szczegółowe ceny produktów'
    }
  ];

  const priceTypeOptions: {
    value: PriceTypeOption;
    icon: string;
    label: string;
    description: string;
  }[] = [
    {
      value: 'gross',
      icon: 'fas fa-money-bill-wave',
      label: 'Brutto',
      description: 'Ceny z podatkiem VAT'
    },
    {
      value: 'net',
      icon: 'fas fa-coins',
      label: 'Netto',
      description: 'Ceny bez podatku VAT'
    },
    {
      value: 'both',
      icon: 'fas fa-balance-scale',
      label: 'Brutto i netto',
      description: 'Obie wartości cenowe'
    }
  ];

  const vatRateOptions: {
    value: VatRateOption;
    icon: string;
    label: string;
    description: string;
  }[] = [
    {
      value: 'vat23',
      icon: 'fas fa-percent',
      label: 'VAT 23%',
      description: 'Standardowa stawka VAT'
    },
    {
      value: 'vat8',
      icon: 'fas fa-percentage',
      label: 'VAT 8%',
      description: 'Obniżona stawka VAT'
    }
  ];

  const renderRadioGroup = <T extends string>(
    options: {
      value: T;
      icon: string;
      label: string;
      description: string;
    }[],
    currentValue: T,
    onChange: (value: T) => void
  ) => (
      <div className="radio-group">
        {options.map((option) => (
          <label
            key={option.value}
            className={`radio-option ${currentValue === option.value ? 'is-active' : ''}`}
          >
            <input
              type="radio"
              name={`${options[0].label}-group`}
              value={option.value}
              checked={currentValue === option.value}
              onChange={() => onChange(option.value)}
              className="radio-input"
            />
            <div className="radio-content">
              <div className="radio-icon">
                <i className={option.icon}></i>
              </div>
              <div className="radio-text">
                <span className="radio-label">{option.label}</span>
                <span className="radio-description">{option.description}</span>
              </div>
            </div>
          </label>
        ))}
      </div>
    );

  return (
    <div className="control-panel no-print">
      <div className="panel-section">
        <h3 className="panel-section-title">
          <i className="fas fa-palette mr-2"></i>Szablon wyceny
        </h3>
        {renderRadioGroup(
          templateOptions,
          template,
          (value) => setTemplate(value as TemplateOption)
        )}
      </div>

      <div className="panel-section">
        <h3 className="panel-section-title">
          <i className="fas fa-chart-pie mr-2"></i>Wyświetlanie cen
        </h3>
        {renderRadioGroup(
          priceDisplayOptions,
          priceDisplayOption,
          (value) => setPriceDisplayOption(value as PriceDisplayOption)
        )}
      </div>

      <div className="panel-section">
        <h3 className="panel-section-title">
          <i className="fas fa-dollar-sign mr-2"></i>Rodzaj ceny
        </h3>
        {renderRadioGroup(
          priceTypeOptions,
          priceType,
          (value) => setPriceType(value as PriceTypeOption)
        )}
      </div>

      <div className="panel-section">
        <h3 className="panel-section-title">
          <i className="fas fa-percent mr-2"></i>Stawka VAT
        </h3>
        {renderRadioGroup(
          vatRateOptions, 
          vatRate, 
          (value) => setVatRate(value as VatRateOption)
        )}
      </div>

      <div className="panel-section actions">
        <div className="action-buttons">
          {showDiscountInput ? (
            <div className="discount-input-group">
              <input
                className="input discount-input"
                type="text"
                value={discountInput}
                onChange={handleDiscountInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Rabat %"
                disabled={isUpdatingDiscount}
              />
              <button
                className={`button is-danger ${isUpdatingDiscount ? 'is-loading' : ''}`}
                onClick={handleDiscountSubmit}
                disabled={isUpdatingDiscount}
              >
                Zastosuj
              </button>
            </div>
          ) : (
            <button
              className="button is-light"
              onClick={() => {
                setShowDiscountInput(true);
                setDiscountInput(discount > 0 ? discount.toString() : '');
              }}
            >
              <span className="icon">
                <i className="fas fa-percent"></i>
              </span>
              <span>Rabat {discount > 0 ? `(${discount}%)` : ''}</span>
            </button>
          )}

          <button
            className="button is-light"
            onClick={handlePrint}
          >
            <span className="icon">
              <i className="fas fa-print"></i>
            </span>
            <span>Drukuj</span>
          </button>

          <button
            className="button is-light"
            onClick={handleSavePDF}
          >
            <span className="icon">
              <i className="fas fa-file-pdf"></i>
            </span>
            <span>Zapisz PDF</span>
          </button>
        </div>
      </div>
    </div>
  );
};
