// src/components/FileDownloadModal/FileDownloadModal.tsx
import React from 'react';
import { Link } from 'react-router-dom';

interface FileDownloadModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'login' | 'premium';
}

export const FileDownloadModal: React.FC<FileDownloadModalProps> = ({ isOpen, onClose, type }) => {
  if (!isOpen) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <span className="icon-text">
              <span className="icon has-text-info">
                <i className="fas fa-info-circle"></i>
              </span>
              <span>{type === 'login' ? 'Wymagane logowanie' : 'Premium wymagane'}</span>
            </span>
          </p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body">
          {type === 'login' ? (
            <div className="content">
              <h3 className="title is-5">Zyskaj dostęp do profesjonalnych zasobów</h3>
              <p>Zaloguj się lub załóż konto, aby korzystać z aplikacji GoEstima i tworzyć wyceny od razu.</p>
              <p>Dodatkowo z kontem Premium otrzymasz:</p>
              <ul>
                <li>Ebook &quot;Od Projektu do Zysku&quot;</li>
                <li>Profesjonalne wzory umów</li>
                <li>Protokoły odbioru, pomiarowe</li>
                <li>Wzory dokumentów reklamacyjnych</li>
                <li>Nielimitowaną liczbę wycen</li>
              </ul>
            </div>
          ) : (
            <div className="content">
              <h3 className="title is-5">Odblokuj pełen dostęp z kontem Premium!</h3>
              <p>Wzory dokumentów, ebook i inne materiały są dostępne dla użytkowników Premium.</p>
              <div className="notification is-info is-light">
                <p className="mb-2"><strong>Z kontem Premium otrzymujesz:</strong></p>
                <p>✓ Profesjonalne wzory dokumentów</p>
                <p>
                  ✓ Ebook &quot;Od Projektu do Zysku - Kompletny Przewodnik dla Profesjonalnych Stolarzy Meblowych&quot; (223 strony)
                </p>
                <p>✓ Nielimitowaną liczbę wycen</p>
                <p>✓ Dostęp do wszystkich funkcji aplikacji</p>
              </div>
            </div>
          )}
        </section>
        <footer className="modal-card-foot is-justify-content-center">
          <div className="buttons">
            {type === 'login' ? (
              <>
                <Link to="/login" className="button is-primary">
                  <span className="icon">
                    <i className="fas fa-sign-in-alt"></i>
                  </span>
                  <span>Zaloguj się</span>
                </Link>
                <Link to="/register" className="button is-info">
                  <span className="icon">
                    <i className="fas fa-user-plus"></i>
                  </span>
                  <span>Załóż konto</span>
                </Link>
              </>
            ) : (
              <Link to="/premium" className="button is-primary">
                <span className="icon">
                  <i className="fas fa-crown"></i>
                </span>
                <span>Sprawdź plany Premium</span>
              </Link>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
};
