import React from "react";
import { Link } from "react-router-dom";
import { User } from "firebase/auth";
import { TodayIs } from "../TodayIs";
import { RecentEstimatesList } from "../RecentEstimatesList";

interface Transaction {
  id: number;
  amount: number;
  invoice_requested: boolean;
  created_at: string;
}

interface UserStats {
  estimateCount: number;
  estimateCountThisMonth: number;
  productCount: number;
}

interface Estimate {
  id: number;
  name: string;
  updated_at: string;
}

interface UserData {
  premium_expiration: string | null;
}

interface LoggedInHomeProps {
  currentUser: User;
  userStats: UserStats | null;
  recentEstimates: Estimate[];
  userData: UserData | null;
  latestTransaction: Transaction | null;
  handleInvoiceRequest: (transactionId: number) => Promise<void>;
}

export const LoggedInHome: React.FC<LoggedInHomeProps> = ({
  currentUser,
  userStats,
  recentEstimates,
  userData,
  latestTransaction,
  handleInvoiceRequest
}) => {
  // Funkcja pomocnicza do sprawdzania statusu premium
  const isPremiumActive = (): boolean => {
    if (!userData?.premium_expiration) return false;
    return new Date(userData.premium_expiration) > new Date();
  };

  const getPremiumExpirationDate = (): string => {
    if (!userData?.premium_expiration) return '';
    return new Date(userData.premium_expiration).toLocaleDateString();
  };

  return (
    <>
      <div className="columns">
        <section className="column is-8">
          <div className={`${isPremiumActive() ? '' : 'is-info'} mb-5`}>
            <div>
              {isPremiumActive() ? (
                <div className="is-flex is-align-items-center">
                  <span className="icon mb-2 has-text-warning">
                    <i className="fas fa-crown"></i>
                  </span>
                  <span className="has-text-weight-bold ml-2">
                    Konto Premium - ważne do {getPremiumExpirationDate()}
                  </span>
                  {latestTransaction && !latestTransaction.invoice_requested && (
                    <button
                      className="button is-small ml-4"
                      onClick={() => handleInvoiceRequest(latestTransaction.id)}
                    >
                      <span className="icon">
                        <i className="fas fa-file-invoice"></i>
                      </span>
                      <span>Poproś o fakturę</span>
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  <span className="icon mb-2">
                    <i className="fas fa-check"></i>
                  </span>
                  <span className="has-text-weight-bold ml-2">
                    Konto darmowe, włącz
                    {' '}
                    <Link
                      to="/premium"
                    >
                      <span>
                        Premium
                      </span>
                      <span className="icon mb-2">
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                      </span>
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="content">
            {userStats && (
              <div>
                <div>
                  <RecentEstimatesList estimates={recentEstimates} />
                </div>
                <div className="columns pt-6 pl-5">
                  {userStats.estimateCount > 0 && (
                    <>
                      <div className="column p-1 pr-5 is-narrow">
                        Ilość wycen:
                        {' '}
                        <strong>{userStats.estimateCount}</strong>
                      </div>
                      <div className="column p-1">
                        W tym miesiącu:
                        {' '}
                        <strong>{userStats.estimateCountThisMonth}</strong>
                      </div>
                    </>
                  )}
                </div>
                <div className="pt-5">
                  <h2 className="title is-4 mb-3">Zarządzaj produktami</h2>
                  <p>
                    Możesz edytować ceny produktów systemowych.<br />
                    Możesz również dodawać własne produkty i używać ich do tworzenia wycen.
                  </p>
                  {userStats.productCount > 0 ? (
                    <p className="pl-4 py-3">
                      Własne produkty: <strong>{userStats.productCount}</strong>
                    </p>
                  ) : (
                    <p className="p-3">
                      <strong>Nie dodano jeszcze własnych produktów...</strong>
                    </p>
                  )}
                  <div>
                    <Link
                      to="/products"
                      className="button m-1"
                    >
                      <span className="icon">
                        <i className="fa-solid fa-couch"></i>
                      </span>
                      <span>
                        Edytuj produkty
                      </span>
                    </Link>
                    {' '}
                    <Link
                      to="/addProduct"
                      className="button m-1"
                    >
                      <span className="icon">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                      <span>
                        Dodaj własny produkt
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="column is-1">
          {' '}
        </section>
        <section className="column is-hidden-touch is-3">
          <div className="content">
            <h2 className="title is-size-4 has-text-centered has-text-info">
              <span className="icon">
                <i className="fa-solid fa-circle-info"></i>
              </span>
              {' '}
              <span>Info</span>
            </h2>
            <span>
              Cześć
              {' '}
              <span className="has-text-info has-text-weight-bold">
                {currentUser.displayName}
              </span>!
            </span>
            <TodayIs />
          </div>
          <div className="column is-full">
            <div className="homepage__custom-separator"></div>
          </div>

          {/* Nowa sekcja z ebookiem */}
          <div className="content">
            <h2 className="title is-size-4 has-text-centered has-text-info">
              <span className="icon">
                <i className="fa-solid fa-book"></i>
              </span>
              {' '}
              <span>Ebook dla stolarzy</span>
            </h2>
            <div className="content has-text-centered">
              <figure className="image mb-3">
                <Link to="/ebooki">
                  <img src="/images/ebooks/od-projektu-do-zysku-cover.png" alt="Od Projektu do Zysku - ebook dla stolarzy" />
                </Link>
              </figure>
              <p className="mb-3">
                <strong>Od Projektu do Zysku</strong> - kompleksowy przewodnik dla profesjonalnych stolarzy meblowych (223 strony)
              </p>
              <Link to="/ebooki" className="button is-info">
                <span className="icon">
                  <i className="fas fa-download"></i>
                </span>
                <span>Pobierz ebooka</span>
              </Link>
            </div>
          </div>
          <div className="column is-full">
            <div className="homepage__custom-separator"></div>
          </div>

          <div className="content">
            <h2 className="title is-size-4 has-text-centered has-text-info">
              <span className="icon">
                <i className="fa-solid fa-file"></i>
              </span>
              {' '}
              <span>Wzory umów</span>
            </h2>
            <div className="content has-text-centered">
              <p>
                Udostępniamy kompleksowy zestaw profesjonalnych wzorów dokumentów, niezbędnych w codziennej pracy stolarza.
              </p>
              <Link to="/wzory-umow" className="button is-info">
                <span className="icon">
                  <i className="fas fa-file-contract"></i>
                </span>
                <span>Zobacz wzory</span>
              </Link>
            </div>
          </div>
          <div className="column is-full">
            <div className="homepage__custom-separator"></div>
          </div>
          <div className="content">
            <h2 className="title is-size-4 has-text-centered has-text-info">
              <span className="icon">
                <i className="fa-solid fa-phone"></i>
              </span>
              {' '}
              <span>Kontakt</span>
            </h2>
            <div className="content">
              <p>Masz pytania / nie wiesz jak zacząć?</p>
              <p>
                Tel. <strong>451 261 661</strong><br />
                Pn. - PT. 15:00 - 20:00
              </p>
            </div>
            <div>
              <p>
                <Link
                  className="has-text-weight-bold"
                  to="/help#faq"
                >
                  Dział pomocy
                </Link>
                <br />
                <span className="has-text-grey">
                  FAQ, instrukcje, video.
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className="is-hidden-desktop mt-6">
          <div className="columns is-multiline">
            {/* Ebook dla stolarzy - sekcja mobilna */}
            <div className="column is-12">
              <div className="has-text-centered">
                <h3 className="title is-5 has-text-info">
                  <span className="icon">
                    <i className="fa-solid fa-book"></i>
                  </span>
                  {' '}
                  <span>Ebook dla stolarzy</span>
                </h3>
                <figure className="image is-128x128 is-inline-block mb-3">
                  <Link to="/ebooki">
                    <img src="/images/ebooks/od-projektu-do-zysku-cover.png" alt="Od Projektu do Zysku - ebook dla stolarzy" />
                  </Link>
                </figure>
                <p className="mb-3 mt-5">
                  <strong>Od Projektu do Zysku</strong> - przewodnik dla stolarzy
                </p>
                <Link to="/ebooki" className="button is-info">
                  <span className="icon">
                    <i className="fas fa-download"></i>
                  </span>
                  <span>Pobierz ebooka</span>
                </Link>
              </div>
            </div>

            {/* Wzory umów - sekcja mobilna */}
            <div className="column is-12 mt-6">
              <div className="has-text-centered">
                <h3 className="title is-5 has-text-info">
                  <span className="icon">
                    <i className="fa-solid fa-file"></i>
                  </span>
                  {' '}
                  <span>Wzory umów</span>
                </h3>
                <p className="mb-3">
                  Profesjonalne wzory dokumentów niezbędne w pracy stolarza
                </p>
                <Link to="/wzory-umow" className="button is-info">
                  <span className="icon">
                    <i className="fas fa-file-contract"></i>
                  </span>
                  <span>Zobacz wzory</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
