// components/EstimateSummary/EstimateSummary.tsx
import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import { useReactToPrint } from 'react-to-print';
import { useUserData } from '../../hooks/useUserData';
import './print-styles.scss';
import './EstimateSummary.scss';
import { getCategoryName } from '../../config/categories';
import {
  CategoryTotal,
  EstimateData,
  PriceDisplayOption,
  PriceTypeOption,
  TemplateOption,
  UserCompany,
  VatRateOption
} from '../../types/EstimateSummary';
import { ControlPanel } from './ControlPanel';

// Importowanie komponentów szablonów
import { DefaultTemplate } from './templates/DefaultTemplate';
import { ProfessionalTemplate } from './templates/ProfessionalTemplate';
import { SimpleTemplate } from './templates/SimpleTemplate';

// Definiujemy typ dla props FallbackTemplate zamiast używać any
interface FallbackTemplateProps {
  estimateData: EstimateData;
  userInfo: UserCompany | null;
  categorySummaries: CategoryTotal[];
  totalPrice: number;
  discountedPrice: number;
  priceDisplayOption: PriceDisplayOption;
  priceType: PriceTypeOption;
  vatRate: VatRateOption; // Dodajemy to pole
}

// Fallback na wypadek, gdyby importy zawiodły
const FallbackTemplate = forwardRef<HTMLDivElement, FallbackTemplateProps>((props, ref) => (
  <div className="column" ref={ref}>
    <div className="message is-danger">
      <div className="message-header">
        <p>Wystąpił problem z załadowaniem szablonu</p>
      </div>
      <div className="message-body">
        <p>Spróbuj wybrać inny szablon lub odświeżyć stronę.</p>
      </div>
    </div>
    <DefaultTemplate
      estimateData={props.estimateData}
      userInfo={props.userInfo}
      categorySummaries={props.categorySummaries}
      totalPrice={props.totalPrice}
      discountedPrice={props.discountedPrice}
      priceDisplayOption={props.priceDisplayOption}
      priceType={props.priceType}
      vatRate={props.vatRate} // Dodajemy przekazywanie vatRate
      ref={ref}
    />
  </div>
));

FallbackTemplate.displayName = 'FallbackTemplate';

interface SummaryProps {
  estimateId: number;
  estimateName: string;
  estimateDescription: string;
  createdAt: string;  // W systemie używane jako created_at
  updatedAt: string;  // W systemie używane jako updated_at
}

export const EstimateSummary: React.FC<SummaryProps> = ({
  estimateId,
  estimateName,
  estimateDescription,
  createdAt,
  updatedAt
}) => {
  const { currentUser } = useAuth();
  const [categorySummaries, setCategorySummaries] = useState<CategoryTotal[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const componentRef = useRef(null);
  const [userInfo] = useUserData();
  const [priceDisplayOption, setPriceDisplayOption] = useState<PriceDisplayOption>('totalOnly');
  const [priceType, setPriceType] = useState<PriceTypeOption>('both');
  const [template, setTemplate] = useState<TemplateOption>('default');
  const [discount, setDiscount] = useState<number>(0);
  const [showDiscountInput, setShowDiscountInput] = useState<boolean>(false);
  const [discountedPrice, setDiscountedPrice] = useState<number>(0);
  const [discountInput, setDiscountInput] = useState<string>('');
  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState<boolean>(false);
  const [vatRate, setVatRate] = useState<VatRateOption>('vat23');

  const fetchSummary = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get(`${config.API_URL}/estimate-products/${estimateId}/summary`, {
        headers: {
          Authorization: `Bearer ${await currentUser.getIdToken()}`
        }
      });

      setCategorySummaries(response.data.categorySummaries.map((category: CategoryTotal) => ({
        ...category,
        category: getCategoryName(category.category),
        total: Number(category.total) || 0,
        products: category.products.map(product => ({
          ...product,
          quantity: Number(product.quantity) || 0,
          price: Number(product.price) || 0,
          width: product.width !== undefined ? Number(product.width) : null,
          height: product.height !== undefined ? Number(product.height) : null,
          priceOption: product.priceOption
        }))
      })));
      setTotalPrice(Number(response.data.totalPrice) || 0);
    } catch (error) {
      console.error('Nie udało się pobrać podsumowania:', error);
    } finally {
      setLoading(false);
    }
  }, [estimateId, currentUser]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const calculateDiscount = useCallback(() => {
    const discountAmount = totalPrice * (discount / 100);
    setDiscountedPrice(totalPrice - discountAmount);
  }, [totalPrice, discount]);

  const updateDiscountInDatabase = useCallback(async (newDiscount: number) => {
    if (!currentUser) return;
    setIsUpdatingDiscount(true);
    try {
      const response = await axios.put(`${config.API_URL}/estimates/${estimateId}/discount`,
        { discount: newDiscount },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );
      if (response.status === 200) {
        setDiscount(newDiscount);
        setDiscountInput(newDiscount.toString());
        calculateDiscount();
      }
    } catch (error) {
      console.error('Error updating discount:', error);
      setDiscount(discount);
      setDiscountInput(discount.toString());
    } finally {
      setIsUpdatingDiscount(false);
      setShowDiscountInput(false);
    }
  }, [estimateId, currentUser, calculateDiscount, discount]);

  useEffect(() => {
    calculateDiscount();
  }, [calculateDiscount]);

  useEffect(() => {
    const fetchEstimateDetails = async () => {
      if (!currentUser) return;
      try {
        const response = await axios.get(`${config.API_URL}/estimates/${estimateId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        // Mapowanie danych z API do naszej struktury
        if (response.data.discount !== undefined) {
          const discountValue = parseFloat(response.data.discount);
          setDiscount(discountValue);
          setDiscountInput(discountValue.toString());
        }
      } catch (error) {
        console.error('Error fetching estimate details:', error);
      }
    };

    fetchEstimateDetails();
  }, [estimateId, currentUser]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 10mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }
    `,
    documentTitle: `Wycena - ${estimateName}`,
  });

  const handleDiscountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setDiscountInput(value);
    }
  };

  const handleDiscountSubmit = () => {
    const newDiscount = discountInput === '' ? 0 : Math.min(100, parseInt(discountInput, 10));
    updateDiscountInDatabase(newDiscount);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleDiscountSubmit();
    }
  };

  const handleSavePDF = useCallback(async () => {
    // Importujemy dynamicznie, aby zredukować rozmiar początkowego ładowania
    const { generatePDFFromTemplate } = await import('../../utils/pdfGenerator');

    generatePDFFromTemplate(
      componentRef.current,
      `Wycena - ${estimateName}`
    );
  }, [estimateName]);

  if (loading) {
    return <div className="spinner is-loading"></div>;
  }

  // Mapujemy dane ze struktury Estimate na EstimateData używane w szablonach
  const estimateData: EstimateData = {
    id: estimateId,
    name: estimateName,
    description: estimateDescription,
    createdAt, // W systemie używamy created_at, ale w komponentach createdAt
    updatedAt, // W systemie używamy updated_at, ale w komponentach updatedAt
    discount
  };

  // Renderowanie odpowiedniego szablonu na podstawie wybranej opcji
  const renderTemplate = () => {
    const commonProps = {
      estimateData,
      userInfo,
      categorySummaries,
      totalPrice,
      discountedPrice,
      priceDisplayOption,
      priceType,
      vatRate,
      ref: componentRef
    };

    try {
      switch (template) {
      case 'professional':
        return <ProfessionalTemplate {...commonProps} />;
      case 'simple':
        return <SimpleTemplate {...commonProps} />;
      default:
        return <DefaultTemplate {...commonProps} />;
      }
    } catch (error) {
      console.error('Błąd podczas renderowania szablonu:', error);
      return <FallbackTemplate {...commonProps} />;
    }
  };

  return (
    <>
      <div className="box content has-text-centered has-background-gradient-green py-3 mt-6">
        <h2 className="title is-4 has-text-white is-flex is-align-items-center is-justify-content-center m-0">
          <span>Podsumowanie wyceny</span>
        </h2>
      </div>

      <ControlPanel
        priceDisplayOption={priceDisplayOption}
        setPriceDisplayOption={setPriceDisplayOption}
        priceType={priceType}
        setPriceType={setPriceType}
        vatRate={vatRate}
        setVatRate={setVatRate}
        template={template}
        setTemplate={setTemplate}
        discount={discount}
        discountInput={discountInput}
        setDiscountInput={setDiscountInput}
        showDiscountInput={showDiscountInput}
        setShowDiscountInput={setShowDiscountInput}
        isUpdatingDiscount={isUpdatingDiscount}
        handleDiscountSubmit={handleDiscountSubmit}
        handleDiscountInputChange={handleDiscountInputChange}
        handleKeyDown={handleKeyDown}
        handlePrint={handlePrint}
        handleSavePDF={handleSavePDF}
      />

      <div className="has-background-gradient-grey box columns content mt-5 mb-6 estimate m-1">
        {renderTemplate()}
      </div>
    </>
  );
};