import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { Layout } from "./components/Layout";
import { App } from "./App";
import { AccountPage } from "./pages/AccountPage";
import { PasswordResetPage } from "./pages/PasswordResetPage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AddProduct } from "./pages/AddProduct";
import { Products } from "./pages/Products";
import { EditProduct } from "./components/EditProduct";
import { EstimateFurniture } from "./pages/EstimateFurniture/EstimateFurniture";
import { EstimateDetails } from "./pages/EstimateFurniture/EstimateDetails";
import { TermsOfService } from "./pages/TermsOfService";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { CreateEstimate } from "./components/CreateEstimate";
import { Changelog } from "./pages/Changelog";
import { Articles } from "./pages/Articles";
import { Article } from "./pages/Articles/Article";
import { NotFound } from "./pages/NotFound";
import { Help } from "./pages/Help";
import { OrderDetails } from "./pages/OrderDetails"; // Utwórz ten komponent
import { AdminPanel } from "./pages/AdminPanel";
import { AdminRoute } from "./components/AdminRoute";
import { Premium } from "./pages/Premium";
import { PaymentSuccess } from "./components/payment/PaymentSuccess";
import { PaymentError } from "./components/payment/PaymentError";
import { Files } from "./pages/Files";
import { Ebooks } from "./pages/Files/Ebooks";

export const Root: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/home" element={<HomePage />} />
      <Route element={<App />}>
        <Route element={<Layout />}>
          <Route path="*" element={<NotFound />} />
          <Route index element={<HomePage />} />
          <Route path="/platnosc/sukces" element={<PaymentSuccess />} />
          <Route path="/platnosc/blad" element={<PaymentError />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="wzory-umow" element={<Files />} />
          <Route path="ebooki" element={<Ebooks />} />
          <Route path="help" element={<Help />} />
          <Route path="changelog" element={<Changelog />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="premium" element={<Premium />} />
          <Route path="articles" element={<Articles />} />
          <Route path="articles/:slug" element={<Article />} />
          <Route path="account" element={<ProtectedRoute />}>
            <Route index element={<AccountPage />} />
          </Route>
          <Route path="admin" element={<AdminRoute />}>
            <Route index element={<AdminPanel />} />
          </Route>
          <Route path="estimate-furniture" element={<ProtectedRoute />}>
            <Route index element={<EstimateFurniture />} />
            <Route path=":estimateId/*" element={<EstimateDetails />} />
          </Route>
          <Route path="orders" element={<ProtectedRoute />}>
            <Route path=":orderId" element={<OrderDetails />} />
          </Route>
          <Route path="create-estimate" element={<ProtectedRoute />}>
            <Route index element={<CreateEstimate />} />
          </Route>
          <Route path="addProduct" element={<ProtectedRoute />}>
            <Route index element={<AddProduct />} />
          </Route>
          <Route path="products" element={<ProtectedRoute />}>
            <Route index element={<Products />} />
            <Route path=":category" element={<Products />} />
            <Route path=":category/edit/:id" element={<EditProduct />} />
          </Route>
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="password-reset" element={<PasswordResetPage />} />
      </Route>
    </Routes>
  </Router>
);
