import React from "react";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import goestima from '../../assets/images/goestima-furniture-estimate.png';
import produkt from '../../assets/images/homepage/produkt.png';
import responsive from '../../assets/images/homepage/responsive.png';
import goestimawycenamebli from '../../assets/images/homepage/goestima-wycena-mebli.jpg';
import ebookCover from '../../assets/images/od-projektu-do-zysku-cover.png';

export const LoggedOutHome: React.FC = () => {
  const imageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }
  });

  const textProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
    delay: 500
  });

  return (
    <div className="is-vcentered">
      <div className="content has-text-centered">
        <h1 className="title is-2 p-6">
          <animated.div style={textProps}>
            Twórz i zarządzaj wycenami mebli na wymiar.<br />
            Z <span className="has-text-info">GoEstima</span>.
          </animated.div>
        </h1>
      </div>
      <div className="content has-text-centered">
        <div className="content has-text-centered">
          <animated.img
            style={{
              ...imageProps,
              maxWidth: '550px',
              width: '100%',
              height: 'auto'
            }}
            src={goestima}
            alt="Furniture Estimate"
            className="homepage__logo"
          />
        </div>
      </div>
      <div className="column">
        <animated.div style={textProps}>
          <h2 className="title is-3 p-3 has-text-centered">
            GoEstima to prawdopodobnie najlepsza aplikacja do tworzenia <span className="has-text-weight-bold is-underlined">wycen mebli</span>.
          </h2>
        </animated.div>
        <div className="columns pt-5">
          <div className="column is-half is-offset-one-quarter">
            <div className="content is-medium">
              <p className="has-text-weight-semibold is-size-4 mb-4 has-text-centered">Nasza aplikacja pozwoli Ci:</p>
              <ul className="has-text-info">
                <li className="mb-2">Błyskawicznie tworzyć profesjonalne wyceny</li>
                <li className="mb-2">Efektywnie zarządzać projektami</li>
                <li className="mb-2">Zwiększyć produktywność i zyski</li>
              </ul>
              <p className="mt-5 is-size-5">
                Z GoEstima proste staje się proste - zamień skomplikowane kalkulacje w intuicyjny proces i skoncentruj się na tym, co potrafisz najlepiej: tworzeniu pięknych mebli.
              </p>
              <p className="is-size-5 has-text-weight-semibold">
                Dołącz do grona zadowolonych meblarzy i przekonaj się, jak GoEstima może zrewolucjonizować Twój biznes!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content has-text-centered pt-6 pb-5">
        <Link to="/register" className="button is-info is-large is-rounded">
          Rozpocznij za darmo
        </Link>
      </div>

      <div className="content has-text-centered mt-6 mb-5">
        <h2 className="title is-3">GoEstima działa i wygląda dobrze na każdym urządzeniu.</h2>
        <img
          src={responsive}
          alt="Responsywny design aplikacji"
          className="pt-3 pb-6"
        />
      </div>

      {/* Nowa sekcja z ebookiem */}
      <div className="box content mt-6 mb-6 p-6">
        <h2 className="title is-1 is-size-3-mobile mt-5 mb-5 has-text-centered">
          Otrzymaj profesjonalny ebook dla stolarzy
        </h2>
        <div className="columns is-vcentered">
          <div className="column is-5 has-text-centered">
            <img
              src={ebookCover}
              alt="Od Projektu do Zysku - Ebook dla stolarzy"
              style={{ maxWidth: '350px', width: '100%' }}
              className="mb-4"
            />
          </div>
          <div className="column is-7">
            <div className="content is-medium">
              <h3 className="title is-3 is-size-3-mobile mb-4">Od Projektu do Zysku: Kompletny Przewodnik dla Profesjonalnych Stolarzy Meblowych</h3>
              <p className="mb-4">
                Kompleksowy przewodnik zawierający 223 strony praktycznej wiedzy niezbędnej
                dla każdego profesjonalnego stolarza. Ten ebook to Twój klucz do zwiększenia
                efektywności i rentowności Twojego biznesu.
              </p>
              <ul className="has-text-info">
                <li className="mb-3">Najnowsze trendy w projektowaniu mebli na 2025 rok</li>
                <li className="mb-3">Skuteczne techniki sprzedaży mebli premium</li>
                <li className="mb-3">Innowacyjne materiały i okucia warte polecenia klientom</li>
                <li className="mb-3">Profesjonalna fotografia produktowa Twoich realizacji</li>
                <li className="mb-3">Marketing cyfrowy dla stolarzy i budowanie portfolio</li>
              </ul>
              <div className="has-text-centered mt-5">
                <Link to="/ebooki" className="button is-info is-medium mr-3">
                  <span className="icon">
                    <i className="fas fa-user-plus"></i>
                  </span>
                  <span>Zobacz ebooka</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content pt-6">
        <h2 className="title is-1 mb-5 has-text-centered">Duża baza gotowych produktów</h2>
        <div className="content">
          <p className="is-size-5 mb-4">
            Aplikacja Goestima oferuje gotową bazę produktów, która obejmuje szeroki zakres kategorii,
            co czyni ją niezwykle wszechstronnym narzędziem do planowania i wyceny mebli oraz wnętrz.
          </p>

          <p className="is-size-5 has-text-weight-semibold mb-3">
            W bazie tej znajdziesz między innymi:
          </p>

          <div className="columns is-multiline title is-4">
            <div className="column is-half">
              <ul className="has-text-info">
                <li>Korpusy</li>
                <li>Blaty</li>
                <li>Podnośniki</li>
                <li>Zawiasy</li>
              </ul>
            </div>
            <div className="column is-half">
              <ul className="has-text-info">
                <li>Fronty</li>
                <li>Szkło</li>
                <li>Oświetlenie</li>
                <li>Wiele innych elementów</li>
              </ul>
            </div>
          </div>
          <p className="is-size-5 mt-4">
            Dzięki temu, korzystając z Goestima, masz dostęp do szerokiego asortymentu produktów,
            co pozwala na łatwe i szybkie tworzenie szczegółowych wycen oraz precyzyjnych planów projektowych.
          </p>
        </div>
      </div>

      <div className="columns mt-6 pb-4 is-vcentered">
        <div className="column has-text-centered">
          <img
            src={produkt}
            alt="Prezentacja produktu w GoEstima"
          />
        </div>
        <div className="column is-two-thirds">
          <h2 className="title is-1 mb-5 has-text-centered">Transparentna wycena przy kliencie</h2>
          <div className="content is-medium">
            <p className="mb-4">
              GoEstima rewolucjonizuje proces wyceny mebli, umożliwiając tworzenie profesjonalnych ofert bezpośrednio przy kliencie.
            </p>
            <p className="mb-4">
              Dzięki eleganckiej prezentacji produktów na ekranie, możesz obrócić ekran w stronę klienta i wspólnie przejść przez proces wyceny. To podejście niesie ze sobą liczne korzyści:
            </p>
            <ul className="has-text-info title is-4">
              <li className="mb-2">Budowanie zaufania poprzez pełną transparentność procesu</li>
              <li className="mb-2">Natychmiastowe dostosowanie oferty do preferencji klienta</li>
              <li className="mb-2">Zwiększone zaangażowanie klienta w proces decyzyjny</li>
              <li className="mb-2">Szybsze podejmowanie decyzji i finalizacja zamówień</li>
            </ul>
            <p className="mt-4 has-text-weight-semibold title is-4">
              Interaktywna prezentacja produktów w GoEstima nie tylko profesjonalizuje Twoją usługę, ale również znacząco skraca czas potrzebny na przygotowanie i akceptację wyceny.
            </p>
          </div>
        </div>
      </div>

      <div className="content mt-6 is-vcentered">
        <h2 className="title is-1 mb-5 has-text-centered">Elastyczne zarządzanie produktami</h2>
        <div className="columns pt-5">
          <div className="column is-half is-offset-one-quarter">
            <div className="content is-medium">
              <p className="has-text-weight-semibold is-size-4 mb-4 has-text-centered">
                GoEstima oferuje niezrównaną elastyczność w zarządzaniu katalogiem produktów, umożliwiając pełną personalizację i kontrolę nad Twoją ofertą.
              </p>
              <ul className="has-text-info">
                <li className="mb-3">Dostosowywanie cen wbudowanych produktów</li>
                <li className="mb-3">Dodawanie własnych, unikalnych produktów</li>
                <li className="mb-3">Wszechstronne opcje wyceny (na cm, sztuki, m², auto-kalkulacja)</li>
                <li className="mb-3">Dodawanie opcji cenowych do produktów</li>
                <li className="mb-3">Elastyczne zarządzanie widocznością produktów</li>
              </ul>
              <p className="has-text-weight-semibold is-size-5 mt-4">
                Dzięki tym funkcjom, GoEstima dostosowuje się do Twojego unikalnego asortymentu i metod wyceny, zapewniając precyzję i elastyczność w każdym projekcie.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="content pt-6 pb-5">
        <img
          src={goestimawycenamebli}
          alt="Wycena mebli w aplikacji GoEstima"
        />
      </div>

      <div className="content mt-6 mb-6 is-vcentered">
        <h2 className="title is-1 mb-4 has-text-centered">Profesjonalna prezentacja i personalizacja</h2>
        <div className="content is-medium">
          <p className="mb-4">
            GoEstima zapewnia nie tylko narzędzia do tworzenia wycen, ale także profesjonalną prezentację wyników i pełną personalizację interfejsu.
          </p>

          <h3 className="subtitle is-4 has-text-weight-semibold mt-5 mb-3">Kluczowe funkcje:</h3>

          <ul className="has-text-info">
            <li className="mb-3">Automatyczny wydruk wyceny</li>
            <li className="mb-3">Generowanie dokumentów w formacie PDF</li>
            <li className="mb-3">Personalizacja z logo Twojej firmy</li>
            <li className="mb-3">Branding w interfejsie aplikacji</li>
          </ul>
          <p className="has-text-weight-semibold is-size-5 mt-4">
            Twoje logo nie tylko pojawi się na wydrukach i plikach PDF, ale również zastąpi logo GoEstima w interfejsie aplikacji, zapewniając spójny wizerunek Twojej marki.
          </p>

          <p className="is-size-5 mt-4">
            Dzięki tym funkcjom, GoEstima staje się nie tylko narzędziem do wycen, ale integralną częścią Twojego profesjonalnego wizerunku, wspierając Cię w budowaniu relacji z klientami.
          </p>
        </div>
      </div>

      <div className="content mt-6 mb-6 is-vcentered">
        <h2 className="title is-1 mb-4 has-text-centered">Profesjonalne wzory umów i dokumentów</h2>
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content is-medium">
              <p className="mb-4">
                Udostępniamy kompleksowy zestaw profesjonalnych wzorów dokumentów, niezbędnych w codziennej pracy stolarza:
              </p>
              <ul className="has-text-info">
                <li className="mb-3">Wzory umów o wykonanie mebli (wersja ogólna i specjalistyczne)</li>
                <li className="mb-3">Protokoły odbioru mebli</li>
                <li className="mb-3">Protokoły reklamacyjne</li>
                <li className="mb-3">Protokoły pomiarowe</li>
              </ul>
              <p className="has-text-weight-semibold is-size-5 mt-4">
                Wszystkie dokumenty są przygotowane przez profesjonalistów i dostosowane do specyfiki branży stolarskiej.
              </p>
              <div className="has-text-centered mt-5">
                <Link to="/wzory-umow" className="button is-info is-medium">
                  <span className="icon">
                    <i className="fas fa-file-contract"></i>
                  </span>
                  <span>Zobacz wzory dokumentów</span>
                </Link>
                <p className="mt-3 has-text-grey">
                  * Do pobrania dokumentów wymagane jest posiadanie konta
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="title is-1 has-text-centered pt-4">
        Docenisz za możliwości.<br />
        Pokochasz za łatwość obsługi.<br />
        <span className="has-text-info">GoEstima</span>.
      </h2>
      <div className="content has-text-centered pt-5 pb-5">
        <Link to="/register" className="button is-info is-large is-rounded">
          Rozpocznij za darmo
        </Link>
      </div>
    </div>
  );
};
