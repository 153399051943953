import React, { useCallback, useEffect, useState } from "react";
import "./HomePage.scss";
import { useAuth } from '../../context/AuthContext';
import { Helmet } from "react-helmet";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import { LoggedInHome } from "../../components/HomePage/LoggedInHome";
import { LoggedOutHome } from "../../components/HomePage/LoggedOutHome";

interface Transaction {
  id: number;
  amount: number;
  invoice_requested: boolean;
  created_at: string;
}

interface UserStats {
  estimateCount: number;
  estimateCountThisMonth: number;
  productCount: number;
}

interface Estimate {
  id: number;
  name: string;
  updated_at: string;
}

interface UserData {
  premium_expiration: string | null;
}

export const HomePage: React.FC = () => {
  const { currentUser } = useAuth();
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [recentEstimates, setRecentEstimates] = useState<Estimate[]>([]);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [latestTransaction, setLatestTransaction] = useState<Transaction | null>(null);

  const fetchUserStats = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<UserStats>(`${config.API_URL}/estimates/user-stats`, {
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        }
      });
      setUserStats(response.data);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  }, [currentUser]);

  const fetchLatestTransaction = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<Transaction>(
        `${config.API_URL}/payments/latest-transaction`,
        {
          headers: {
            'Authorization': `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );
      setLatestTransaction(response.data);
    } catch (error) {
      console.error('Error fetching latest transaction:', error);
    }
  }, [currentUser]);

  const handleInvoiceRequest = async (transactionId: number) => {
    if (!currentUser) {
      toast.error('Nie jesteś zalogowany');
      return;
    }
  
    try {
      const token = await currentUser.getIdToken();
      await axios.post(
        `${config.API_URL}/payments/request-invoice/${transactionId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      toast.success('Prośba o fakturę została wysłana. Faktura zostanie dostarczona na Twój adres email.');
      await fetchLatestTransaction(); // Odśwież dane transakcji
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        if (error.response.data.missingFields) {
          toast.error('Aby otrzymać fakturę, uzupełnij dane firmy w ustawieniach konta.');
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error('Wystąpił błąd podczas wysyłania prośby o fakturę.');
      }
    }
  };

  const fetchRecentEstimates = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<Estimate[]>(`${config.API_URL}/estimates/recent`, {
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        },
        params: {
          limit: 7
        }
      });
      setRecentEstimates(response.data);
    } catch (error) {
      console.error('Error fetching recent estimates:', error);
    }
  }, [currentUser]);

  // Funkcja do pobierania danych o premium
  const fetchUserData = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<UserData>(`${config.API_URL}/account/users/${currentUser.uid}`, {
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [currentUser]);

  // Pobieranie wszystkich danych po zalogowaniu
  useEffect(() => {
    if (currentUser) {
      fetchUserData();
      fetchUserStats();
      fetchRecentEstimates();
      fetchLatestTransaction();
    }
  }, [currentUser, fetchUserStats, fetchRecentEstimates, fetchLatestTransaction, fetchUserData]);

  return (
    <div className="homepage p-4 pb-6">
      <Helmet>
        <title>GoEstima - BEZPŁATNY kalkulator mebli kuchennych dla stolarzy i SMK</title>
        <meta name="description" content="Kalkulator mebli kuchennych | Aplikacja do kompleksowego zarządzania wycenami i umowami | Meble na wymiar." />
        <link rel="canonical" href="https://goestima.pl" />
        <meta property="og:url" content="https://goestima.pl/" />
        <meta property="og:title" content="GoEstima - kalkulator mebli | aplikacja do wycen mebli" />
        <meta property="og:description" content="Kalkulator mebli kuchennych | Aplikacja do kompleksowego zarządzania wycenami i umowami | Meble na wymiar." />
        <meta property="og:image" content="https://goestima.pl/goestima-og.jpg" />
      </Helmet>
      
      {currentUser ? (
        <LoggedInHome 
          currentUser={currentUser}
          userStats={userStats}
          recentEstimates={recentEstimates}
          userData={userData}
          latestTransaction={latestTransaction}
          handleInvoiceRequest={handleInvoiceRequest}
        />
      ) : (
        <LoggedOutHome />
      )}
    </div>
  );
};
