// components/EstimateSummary/SummaryHeader.tsx
import React from 'react';
import { UserCompany } from '../../types/EstimateSummary';
import { getConditionalUrl } from '../../utils/cdnHelper';
import logoImage from '../../assets/images/logo.png';

interface SummaryHeaderProps {
  estimateName: string;
  estimateDescription: string;
  createdAt: string;
  updatedAt: string;
  userInfo: UserCompany | null;
}

export const SummaryHeader: React.FC<SummaryHeaderProps> = ({
  estimateName,
  estimateDescription,
  createdAt,
  updatedAt,
  userInfo
}) => {
  return (
    <div className="columns print-header">
      <div className="column">
        <p>Wycena przygotowana dla:</p>
        <h2 className="summary-title mt-3"><span className="has-text-info">{estimateName}</span></h2>
        <p className="mt-3">{estimateDescription}</p>
        <p className="mt-3"><strong>Utworzona:</strong> {new Date(createdAt).toLocaleDateString()}</p>
        <p><strong>Aktualizacja:</strong> {new Date(updatedAt).toLocaleDateString()}</p>
      </div>
      <div className="column print-logo-column">
        {userInfo?.company_logo ? (
          <img src={getConditionalUrl(userInfo.company_logo)} alt="Logo firmy" id="companyLogo" />
        ) : (
          <img src={logoImage} alt="GoEstima" id="companyLogo" />
        )}
        <p className="mt-3">
          <span className="icon-text">
            <span>Wydrukowano: {new Date().toLocaleDateString()}</span>
          </span>
        </p>
        {userInfo?.company_name && (
          <p className="mt-4">
            <span>{userInfo.company_name}</span>
          </p>
        )}
        {userInfo?.address_line && (
          <p className="mt-2">
            <span>{userInfo.address_line}</span>
          </p>
        )}
        {(userInfo?.zipcode_postal || userInfo?.city) && (
          <p className="mt-2">
            <span>
              {userInfo.zipcode_postal && `${userInfo.zipcode_postal} `}
              {userInfo.city}
            </span>
          </p>
        )}
        {userInfo?.company_nip && (
          <p className="mt-2">
            <span>NIP: {userInfo.company_nip}</span>
          </p>
        )}
        {userInfo?.phone_number && (
          <p className="mt-2">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-phone"></i>
              </span>
              <span>{userInfo.phone_number}</span>
            </span>
          </p>
        )}
        {userInfo?.email && (
          <p className="mt-2">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-envelope"></i>
              </span>
              <span>{userInfo.email}</span>
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
