// utils/priceCalculations.ts

/**
 * Stała stawka VAT
 */
export const VAT_RATE = 0.23;
export const VAT_RATE8 = 0.08;
/**
 * Konwertuje cenę brutto na netto
 * @param grossPrice - cena brutto
 * @param vatRate - stawka VAT (domyślnie standardowa 23%)
 * @returns cena netto
 */
export const calculateNetPrice = (grossPrice: number, vatRate: number = VAT_RATE): number => {
  return grossPrice / (1 + vatRate);
};

// Dodajemy nowe funkcje do obsługi przeliczeń VAT
/**
 * Konwertuje cenę brutto (23% VAT) na netto
 * @param grossPrice23 - cena brutto z VAT 23%
 * @returns cena netto
 */
export const calculateNetPriceFromGross23 = (grossPrice23: number): number => {
  return grossPrice23 / (1 + VAT_RATE);
};

/**
 * Konwertuje cenę netto na brutto z wybraną stawką VAT
 * @param netPrice - cena netto
 * @param vatRate - stawka VAT
 * @returns cena brutto z wybraną stawką VAT
 */
export const calculateGrossPriceWithVat = (netPrice: number, vatRate: number): number => {
  return netPrice * (1 + vatRate);
};

/**
 * Oblicza cenę brutto z inną stawką VAT na podstawie ceny brutto z VAT 23%
 * @param grossPrice23 - cena brutto z VAT 23%
 * @param newVatRate - nowa stawka VAT
 * @returns cena brutto z nową stawką VAT
 */
export const recalculateGrossPrice = (grossPrice23: number, newVatRate: number): number => {
  // Najpierw konwertujemy do netto
  const netPrice = calculateNetPriceFromGross23(grossPrice23);
  // Następnie obliczamy nową cenę brutto
  return calculateGrossPriceWithVat(netPrice, newVatRate);
};

/**
 * Oblicza wartość VAT
 * @param grossPrice - cena brutto
 * @param vatRate - stawka VAT (domyślnie standardowa 23%)
 * @returns wartość VAT
 */
export const calculateVatAmount = (grossPrice: number, vatRate: number = VAT_RATE): number => {
  const netPrice = calculateNetPrice(grossPrice, vatRate);
  return grossPrice - netPrice;
};

/**
 * Formatuje cenę jako string z separatorem tysięcy i dwoma miejscami po przecinku
 * @param price - cena do sformatowania
 * @returns sformatowana cena
 */
export const formatPrice = (price: number): string => {
  return price.toLocaleString('pl-PL', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

/**
 * Formatuje cenę jako string z separatorem tysięcy bez miejsc po przecinku
 * @param price - cena do sformatowania
 * @returns sformatowana cena
 */
export const formatPriceInt = (price: number): string => {
  return price.toLocaleString('pl-PL', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};


/**
 * Zwraca wartość stawki VAT na podstawie wybranej opcji
 * @param vatRateOption - wybrana opcja stawki VAT
 * @returns wartość stawki VAT (0.23 lub 0.08)
 */
export const getVatRate = (vatRateOption: 'vat23' | 'vat8'): number => {
  return vatRateOption === 'vat23' ? VAT_RATE : VAT_RATE8;
};