// src/components/CreateOrderButton/CreateOrderButton.tsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "../Tooltip";
import './CreateOrderButton.scss';

interface CreateOrderButtonProps {
  estimateId: number;
}

export const CreateOrderButton: React.FC<CreateOrderButtonProps> = ({ estimateId }) => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [isChecking, setIsChecking] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkExistingOrder = async () => {
      if (!currentUser) return;

      setIsChecking(true);
      try {
        const response = await axios.get(
          `${config.API_URL}/orders/estimate/${estimateId}`,
          {
            headers: {
              Authorization: `Bearer ${await currentUser.getIdToken()}`
            }
          }
        );

        if (response.data.orderId) {
          setOrderId(response.data.orderId);
        }
        // Nie ma potrzeby obsługi else, ponieważ orderId pozostanie null
      } catch (error) {
        console.error('Błąd podczas sprawdzania istniejącego zamówienia:', error);
      } finally {
        setIsChecking(false);
      }
    };

    checkExistingOrder();
  }, [estimateId, currentUser]);

  const handleCreateOrder = async () => {
    if (!currentUser) return;

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${config.API_URL}/orders`,
        { estimateId },
        {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        }
      );

      if (response.data.orderId) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setOrderId(response.data.orderId);
        navigate(`/orders/${response.data.orderId}`);
      }
    } catch (error) {
      console.error('Błąd podczas tworzenia zamówienia:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoToOrder = () => {
    if (orderId) {
      navigate(`/orders/${orderId}`);
    }
  };

  if (isChecking) {
    return <button className="button is-loading">Sprawdzanie...</button>;
  }

  if (orderId) {
    return (
      <>
        <button className="button mr-2 is-info button--fullwidth" onClick={handleGoToOrder}>
          <span className="icon-text">
            <span className="icon">
              <i className="fa-solid fa-paperclip"></i>
            </span>
            <span>
              Zamówienie
            </span>
            <span className="icon">
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </span>
        </button>
        {' '}
        <Tooltip
          content="Zarządzaj realizacją Zamówienia."
          iconClassName="fa-regular fa-circle-question"
        />
      </>
    );
  }

  return (
    <>
      <button
        className={`button is-info mr-2 button--fullwidth ${isLoading ? 'is-loading' : ''}`}
        onClick={handleCreateOrder}
        disabled={isLoading}
      >
        <span className="icon-text">
          <span className="icon">
            <i className="fa-solid fa-paperclip"></i>
          </span>
          <span>
            {isLoading ? 'Tworzenie Zamówienia...' : 'Utwórz zamówienie'}
          </span>
        </span>
      </button>
      {' '}
      <Tooltip
        content="Kliknij, aby utworzyć Zamówienie i zarządzać jego realizacją."
        iconClassName="fa-regular fa-circle-question"
      />
    </>
  );
};
