import React, { useState } from "react";
import "./Files.scss";
import { Helmet } from "react-helmet";
import { DownloadButton } from "../../components/DownloadButton";
import { FileDownloadModal } from "../../components/FileDownloadModal";
import { TableOfContentsModal } from "../../components/TableOfContentsModal";

// Usunięto nieużywany komponent EbookCard

interface EbookChapter {
  title: string;
  topics: string[];
}

interface Ebook {
  imageSrc: string;
  title: string;
  description: string;
  features: string[];
  forWhom: string[];
  chapters: EbookChapter[];
  fileIds: {
    pdf: string;
    epub?: string;
  };
  pageCount: number;
  author: string;
  authorBio: string;
}

export const Ebooks: React.FC = () => {
  const [modalType, setModalType] = useState<'login' | 'premium' | null>(null);
  const [tocModalOpen, setTocModalOpen] = useState<boolean>(false);

  const tableOfContents: string[] = [
    "Wprowadzenie",
    "• O autorze",
    "• Jak korzystać z tego przewodnika",
    "• Dla kogo jest ten ebook",
    "Rozdział 1: Trendy w projektowaniu kuchni i mebli na wymiar na 2025 rok - co klienci naprawdę chcą",
    "• Wprowadzenie",
    "• Zrównoważony design i ekologiczne materiały",
    "• Wielofunkcyjność i elastyczność",
    "• Naturalne materiały i organiczne formy",
    "• Ciemne kolory i tekstury",
    "• Inteligentne meble i technologiczne innowacje",
    "• Spersonalizowane detale i unikalne rozwiązania",
    "• Otwarte półki i przeszklone fronty",
    "• Łączenie różnych materiałów i faktur",
    "• Ergonomia i komfort użytkowania",
    "• Wnioski i rekomendacje dla stolarzy",
    "Rozdział 2: Optymalizacja procesu wyceny - jak nie tracić czasu i zwiększyć konwersję zapytań",
    "• Wprowadzenie",
    "• Standaryzacja procesu wyceny",
    "• Narzędzia do automatyzacji wycen",
    "• Tworzenie bazy standardowych elementów",
    "• Segmentacja klientów i elastyczne podejście do wyceny",
    "• Skuteczna komunikacja wyceny",
    "• Zarządzanie zmianami w wycenie",
    "• Analiza skuteczności wycen",
    "• Zarządzanie oczekiwaniami klientów",
    "• Wycena jako narzędzie marketingowe",
    "• Zarządzanie priorytetami w procesie wyceny",
    "• Podsumowanie",
    "Rozdział 3: Skuteczne techniki sprzedaży mebli premium - jak przekonać klienta do wyższej jakości i ceny",
    "• Wprowadzenie",
    "• Psychologia klienta premium",
    "• Budowanie pozycji eksperta",
    "• Technika wartości zamiast ceny",
    "• Sprzedaż przez doświadczenie",
    "• Technika opcji i kontrastów",
    "• Przezwyciężanie obiekcji cenowych",
    "• Budowanie długoterminowych relacji",
    "• Techniki prezentacji i demonstracji",
    "• Język sprzedaży premium",
    "• Znaczenie autorytetu i rekomendacji",
    "• Wykorzystanie ograniczonej dostępności",
    "• Negocjacje i elastyczność cenowa",
    "• Podsumowanie",
    "Rozdział 4: Nowoczesne materiały i okucia - przegląd innowacji wartych polecenia klientom",
    "• Wprowadzenie",
    "• Innowacyjne płyty meblowe",
    "• Nowoczesne fronty meblowe",
    "• Rewolucyjne systemy szuflad",
    "• Innowacyjne systemy drzwi i frontów",
    "• Blaty i powierzchnie robocze",
    "• Innowacyjne systemy oświetlenia meblowego",
    "• Akcesoria i systemy organizacji",
    "• Powierzchnie antybakteryjne i łatwe w czyszczeniu",
    "• Materiały zrównoważone i ekologiczne",
    "• Inteligentne materiały i powierzchnie",
    "• Zaawansowane okucia i mechanizmy",
    "• Jak prezentować i sprzedawać nowoczesne materiały i okucia",
    "• Gdzie szukać inspiracji i wiedzy",
    "• Podsumowanie",
    "Rozdział 5: Zarządzanie projektami - jak prowadzić kilka zleceń równocześnie bez stresu",
    "• Wprowadzenie",
    "• Podstawy efektywnego zarządzania projektami stolarskimi",
    "• Tworzenie realistycznych harmonogramów",
    "• Zarządzanie łańcuchem dostaw i logistyką",
    "• Efektywna komunikacja z klientami podczas realizacji",
    "• Zarządzanie zespołem i podwykonawcami",
    "• Narzędzia cyfrowe wspomagające zarządzanie projektami",
    "• Reagowanie na nieprzewidziane sytuacje i opóźnienia",
    "• Równoważenie wielu projektów jednocześnie",
    "• Dokumentacja projektowa i procesy kontroli jakości",
    "• Finalizacja projektu i obsługa posprzedażowa",
    "• Analiza zakończonych projektów i wnioski na przyszłość",
    "• Podsumowanie",
    "Rozdział 6: Kalkulatory i szablony do szybkiego projektowania i wyceny typowych mebli",
    "• Wprowadzenie",
    "• Podstawowe kalkulatory dla stolarzy meblowych",
    "• Szablony do projektowania standardowych elementów",
    "• Baza danych materiałów i komponentów",
    "• Automatyzacja obliczeń rozkroju materiałów",
    "• Szablony dokumentów dla standardowych procesów",
    "• Narzędzia do szybkiej wizualizacji projektów",
    "• Integracja systemów kalkulacji z procesem produkcji",
    "• Tworzenie własnych narzędzi kalkulacyjnych",
    "• Zarządzanie bazą szablonów i jej aktualizacja",
    "• Praktyczne zastosowanie kalkulatorów w codziennej pracy",
    "• Podsumowanie",
    "Rozdział 7: Fotografia produktowa - jak robić profesjonalne zdjęcia swoich realizacji",
    "• Wprowadzenie",
    "• Podstawy fotografii produktowej dla stolarzy",
    "• Niezbędny sprzęt i akcesoria",
    "• Oświetlenie w fotografii mebli",
    "• Kompozycja i kadrowanie zdjęć wnętrz",
    "• Przygotowanie mebli i wnętrz do sesji zdjęciowej",
    "• Techniki fotografowania różnych typów mebli",
    "• Podstawowa obróbka zdjęć",
    "• Tworzenie spójnego stylu wizualnego",
    "• Organizacja i archiwizacja zdjęć",
    "• Współpraca z profesjonalnymi fotografami",
    "• Podsumowanie",
    "Rozdział 8: Marketing cyfrowy dla stolarzy - jak skutecznie promować się w sieci",
    "• Wprowadzenie",
    "• Podstawy marketingu cyfrowego dla firm stolarskich",
    "• Tworzenie skutecznej strony internetowej",
    "• Pozycjonowanie lokalne w wyszukiwarkach",
    "• Media społecznościowe dla stolarzy",
    "• Content marketing w branży meblarskiej",
    "• Reklamy online dla lokalnego biznesu",
    "• Marketing przez e-mail i newslettery",
    "• Platformy specjalistyczne i katalogi branżowe",
    "• Współpraca z architektami i projektantami wnętrz",
    "• Mierzenie skuteczności działań marketingowych",
    "• Budżetowanie działań marketingowych",
    "• Podsumowanie",
    "Rozdział 9: Budowanie portfolio i referencji - jak wykorzystać ukończone projekty",
    "• Wprowadzenie",
    "• Strategiczne planowanie portfolio",
    "• Dokumentacja procesu powstawania mebli",
    "• Organizacja i prezentacja portfolio",
    "• Zbieranie i wykorzystywanie rekomendacji klientów",
    "• Tworzenie studiów przypadku",
    "• Specjalizacja i wyróżnianie się na rynku",
    "• Wykorzystanie portfolio w procesie sprzedaży",
    "• Portfolio cyfrowe vs. tradycyjne",
    "• Aktualizacja i rozwój portfolio",
    "• Ochrona własności intelektualnej",
    "• Podsumowanie",
    "Rozdział 10: Legalny biznes - umowy, gwarancje i zabezpieczenia dla stolarza",
    "• Wprowadzenie",
    "• Podstawy prawne działalności stolarskiej",
    "• Tworzenie skutecznych umów z klientami",
    "• Warunki realizacji i aneksy do umów",
    "• Ochrona przed trudnymi klientami",
    "• Polityka zaliczek i płatności",
    "• Gwarancje i serwis posprzedażowy",
    "• Ubezpieczenia dla stolarzy i warsztatów",
    "• Prawa i obowiązki w przypadku reklamacji",
    "• Dokumentacja techniczna i certyfikaty",
    "• Współpraca z podwykonawcami - aspekty prawne",
    "• Ochrona własnych projektów i rozwiązań",
    "• Podsumowanie",
    "Dodatki",
    "• Szablony umów z klientami",
    "• Checklisty do kontroli jakości",
    "• Wzory ankiet dla klientów",
    "• Lista przydatnych zasobów i narzędzi"
  ];

  const ebook: Ebook = {
    imageSrc: "/images/ebooks/od-projektu-do-zysku-cover.png",
    title: "Od Projektu do Zysku: Kompletny Przewodnik dla Profesjonalnych Stolarzy Meblowych",
    description: "Kompendium wiedzy dla stolarzy meblowych, którzy chcą rozwinąć swój biznes i zwiększyć rentowność. Książka zawiera praktyczne wskazówki dotyczące wyceny, sprzedaży, realizacji projektów oraz marketingu.",
    features: [
      "10 obszernych rozdziałów",
      "223 strony praktycznej wiedzy",
      "Gotowe szablony i kalkulatory",
      "Najnowsze trendy w projektowaniu mebli",
      "Techniki sprzedaży mebli premium"
    ],
    forWhom: [
      "Początkujący stolarze zakładający własną działalność",
      "Doświadczeni rzemieślnicy szukający optymalizacji procesów",
      "Właściciele małych i średnich zakładów stolarskich",
      "Projektanci mebli chcący zrozumieć perspektywę wykonawcy",
      "Pracownicy firm stolarskich planujący rozwój zawodowy"
    ],
    chapters: [
      {
        title: "Trendy w projektowaniu kuchni i mebli na wymiar na 2025 rok",
        topics: [
          "Zrównoważony design i ekologiczne materiały",
          "Wielofunkcyjność i elastyczność",
          "Inteligentne meble i technologiczne innowacje",
          "Łączenie różnych materiałów i faktur"
        ]
      },
      {
        title: "Optymalizacja procesu wyceny",
        topics: [
          "Standaryzacja procesu wyceny",
          "Narzędzia do automatyzacji wycen",
          "Skuteczna komunikacja wyceny",
          "Wycena jako narzędzie marketingowe"
        ]
      },
      {
        title: "Skuteczne techniki sprzedaży mebli premium",
        topics: [
          "Psychologia klienta premium",
          "Technika wartości zamiast ceny",
          "Przezwyciężanie obiekcji cenowych",
          "Język sprzedaży premium"
        ]
      },
      {
        title: "Zarządzanie projektami stolarskimi",
        topics: [
          "Tworzenie realistycznych harmonogramów",
          "Zarządzanie łańcuchem dostaw i logistyką",
          "Efektywna komunikacja z klientami",
          "Równoważenie wielu projektów jednocześnie"
        ]
      }
    ],
    fileIds: {
      pdf: "od-projektu-do-zysku-ebook.pdf",
      epub: "od-projektu-do-zysku-ebook.epub"
    },
    pageCount: 223,
    author: "Krzysztof Janiewicz",
    authorBio: "Od 10 lat związany z branżą stolarską. Założyciel firmy Janiewicz's oraz twórca GoEstima - profesjonalnego narzędzia do wyceny mebli. Łączy praktyczną wiedzę o meblarstwie z umiejętnościami z zakresu marketingu i technologii."
  };

  return (
    <div className="section pt-5 files-page">
      <Helmet>
        <title>Od Projektu do Zysku: Ebook dla stolarzy meblowych | GoEstima</title>
        <meta
          name="description"
          content="Kompletny przewodnik dla profesjonalnych stolarzy meblowych. Zwiększ rentowność swojej firmy stolarskiej z praktycznym ebookiem od GoEstima!"
        />
        <link rel="canonical" href="https://goestima.pl/ebooki" />
      </Helmet>

      {/* Mobile book icon header */}
      <div className="is-hidden-tablet has-text-centered mb-5">
        <i className="fas fa-book-open fa-4x has-text-primary d-block mb-4"></i>
        <h2 className="title is-4">
          Ebook dla profesjonalnych stolarzy
        </h2>
      </div>

      {/* Desktop book icon header */}
      <h2 className="title is-3 mb-4 has-text-centered is-hidden-mobile">
        <span className="icon-text is-justify-content-center">
          <span className="icon mr-3">
            <i className="fas fa-book-open has-text-primary"></i>
          </span>
          <span>Ebook dla profesjonalnych stolarzy</span>
        </span>
      </h2>

      <div className="container mb-6">
        <div className="content mb-6">
          <div className="notification files-notification is-info is-light mb-6">
            <div className="columns is-vcentered">
              <div className="column">
                <p className="is-size-4 has-text-centered mb-5">
                  <strong>Rozwiń swój biznes stolarski!</strong> Skorzystaj z wiedzy i doświadczenia
                  zebranymi w kompletnym przewodniku dla profesjonalnych stolarzy meblowych.
                </p>

                <div className="columns is-multiline is-mobile files-benefits-grid">
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="files-icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Praktyczne wskazówki</span>
                      </span>
                    </div>
                  </div>
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Gotowe szablony</span>
                      </span>
                    </div>
                  </div>
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Sprawdzone w praktyce</span>
                      </span>
                    </div>
                  </div>
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Dostęp bezterminowy</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column is-narrow is-hidden-mobile">
                <span className="icon is-large files-shield-icon">
                  <i className="fas fa-graduation-cap fa-3x has-text-info"></i>
                </span>
              </div>
            </div>
          </div>

          <div className="columns is-variable is-8 mb-6">
            <div className="column is-5">
              <div className="mb-5">
                <div className="card-image">
                  <figure className="image">
                    <img src={ebook.imageSrc} alt={ebook.title} />
                  </figure>
                </div>
              </div>

              <div className="buttons is-centered mb-4">
                <DownloadButton
                  fileId={ebook.fileIds.pdf}
                  buttonLabel={
                    <span className="icon-text">
                      <span className="icon">
                        <i className="fas fa-file-pdf"></i>
                      </span>
                      <span>Pobierz PDF</span>
                    </span>
                  }
                  onNeedLogin={() => setModalType('login')}
                  onNeedPremium={() => setModalType('premium')}
                />
              </div>

              {ebook.fileIds.epub && (
                <div className="buttons is-centered">
                  <DownloadButton
                    fileId={ebook.fileIds.epub}
                    buttonLabel={
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-book-reader"></i>
                        </span>
                        <span>Pobierz EPUB</span>
                      </span>
                    }
                    onNeedLogin={() => setModalType('login')}
                    onNeedPremium={() => setModalType('premium')}
                  />
                </div>
              )}

              <div className="box mt-5">
                <h4 className="title is-5 mb-3">Informacje o ebooku</h4>
                <ul className="ml-5 fa-ul">
                  <li className="mb-2">
                    <span className="fa-li"><i className="fas fa-file-alt"></i></span>
                    <strong>Stron:</strong> {ebook.pageCount}
                  </li>
                  <li className="mb-2">
                    <span className="fa-li"><i className="fas fa-user"></i></span>
                    <strong>Autor:</strong> {ebook.author}
                  </li>
                  <li className="mb-2">
                    <span className="fa-li"><i className="fas fa-calendar-alt"></i></span>
                    <strong>Data publikacji:</strong> Styczeń 2025
                  </li>
                  <li className="mb-2">
                    <span className="fa-li"><i className="fas fa-language"></i></span>
                    <strong>Język:</strong> Polski
                  </li>
                </ul>
                <div className="has-text-centered mt-4">
                  <button
                    className="button is-small is-link is-light"
                    onClick={() => setTocModalOpen(true)}
                  >
                    <span className="icon">
                      <i className="fas fa-list-ul"></i>
                    </span>
                    <span>Zobacz spis treści</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="column is-7">
              <h3 className="title is-4 mb-3">{ebook.title}</h3>
              <p className="mb-5">{ebook.description}</p>

              <div className="box mb-5">
                <h4 className="title is-5 mb-3">O autorze</h4>
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-64x64">
                      <img className="is-rounded" src="/images/author-avatar.jpg" alt="Krzysztof Janiewicz" />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-5 mb-2">{ebook.author}</p>
                    <p>{ebook.authorBio}</p>
                  </div>
                </div>
              </div>

              <h4 className="title is-5 mb-3">Co znajdziesz w środku?</h4>
              <div className="columns is-multiline mb-5">
                {ebook.features.map((feature, index) => (
                  <div key={index} className="column is-6">
                    <div className="is-flex">
                      <span className="icon has-text-primary mr-2">
                        <i className="fas fa-star"></i>
                      </span>
                      <span>{feature}</span>
                    </div>
                  </div>
                ))}
              </div>

              <h4 className="title is-5 mb-3">Najważniejsze rozdziały</h4>
              <div className="content mb-5">
                <ul className="mb-5">
                  {ebook.chapters.map((chapter, index) => (
                    <li key={index} className="mb-3">
                      <strong>{chapter.title}</strong>
                      <ul className="mt-1">
                        {chapter.topics.map((topic, i) => (
                          <li key={i}>{topic}</li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                <p><em>...i wiele więcej tematów w pełnej wersji ebooka!</em></p>
              </div>

              <h4 className="title is-5 mb-3">Dla kogo jest ten ebook?</h4>
              <div className="content">
                <ul>
                  {ebook.forWhom.map((person, index) => (
                    <li key={index}><strong>{person}</strong></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="box has-background-primary-light p-5 mb-6">
            <div className="columns is-vcentered">
              <div className="column is-2 has-text-centered is-hidden-mobile">
                <span className="icon is-large">
                  <i className="fas fa-lightbulb fa-3x has-text-primary"></i>
                </span>
              </div>
              <div className="column">
                <h3 className="title is-4 mb-3">Jak korzystać z tego ebooka?</h3>
                <p className="mb-3">
                  &quot;Od Projektu do Zysku&quot; został zaprojektowany tak, aby był praktycznym narzędziem
                  w codziennej pracy stolarza meblowego. Możesz czytać go od początku do końca,
                  traktując jako kompleksowy kurs prowadzenia biznesu stolarskiego, lub wybierać
                  konkretne rozdziały odpowiadające obszarom, które chcesz doskonalić.
                </p>
                <p>
                  Każdy rozdział zawiera nie tylko teoretyczne podstawy, ale przede wszystkim
                  praktyczne wskazówki i przykłady z życia. Na końcu rozdziałów znajdziesz podsumowanie
                  najważniejszych punktów oraz listę kontrolną, która pomoże Ci wdrożyć omawiane
                  zagadnienia w swojej firmie.
                </p>
              </div>
            </div>
          </div>

          <div className="has-text-centered mb-6">
            <h3 className="title is-4 mb-4">Gotowy do rozwoju swojego biznesu stolarskiego?</h3>
            <div className="buttons is-centered">
              <DownloadButton
                fileId={ebook.fileIds.pdf}
                buttonLabel={
                  <span>Pobierz ebooka teraz</span>
                }
                onNeedLogin={() => setModalType('login')}
                onNeedPremium={() => setModalType('premium')}
              />
            </div>
          </div>

          <div className="notification is-warning is-light">
            <p className="has-text-centered">
              <strong>Uwaga:</strong> Ebook dostępny wyłącznie dla użytkowników z planem Premium.
              Jeśli masz już konto Premium, zaloguj się, aby pobrać. Jeśli nie masz jeszcze konta
              Premium, dowiedz się więcej o korzyściach płynących z subskrypcji.
            </p>
          </div>
        </div>
      </div>

      <div className="main">
        <div className="content has-text-grey is-size-7 has-text-centered mt-6 pt-6 disclaimer-text">
          <p>
            Wszelkie prawa zastrzeżone. Ebook &quot;Od Projektu do Zysku: Kompletny Przewodnik dla
            Profesjonalnych Stolarzy Meblowych&quot; jest własnością intelektualną firmy GoEstima.
            Kopiowanie, rozpowszechnianie lub wykorzystywanie treści bez zgody autora jest zabronione.
          </p>
        </div>
      </div>

      <FileDownloadModal
        isOpen={modalType !== null}
        onClose={() => setModalType(null)}
        type={modalType || 'login'}
      />
      <TableOfContentsModal
        isOpen={tocModalOpen}
        onClose={() => setTocModalOpen(false)}
        contents={tableOfContents}
      />
    </div>
  );
};
