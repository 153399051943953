// src/components/TableOfContentsModal/TableOfContentsModal.tsx
import React from 'react';

interface TableOfContentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  contents: string[];
}

export const TableOfContentsModal: React.FC<TableOfContentsModalProps> = ({ isOpen, onClose, contents }) => {
  if (!isOpen) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <span className="icon-text">
              <span className="icon has-text-primary">
                <i className="fas fa-list-ul"></i>
              </span>
              <span>Spis treści ebooka</span>
            </span>
          </p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body toc-modal-body">
          <div className="content">
            <ul className="toc-list">
              {contents.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-center">
          <button className="button is-primary" onClick={onClose}>
            <span className="icon">
              <i className="fas fa-check"></i>
            </span>
            <span>Zamknij</span>
          </button>
        </footer>
      </div>
    </div>
  );
};
