// components/EstimateSummary/templates/SimpleTemplate.tsx
import React, { forwardRef } from 'react';
import { CategoryTotal, EstimateData, PriceDisplayOption, PriceTypeOption, UserCompany, Product, VatRateOption } from '../../../types/EstimateSummary';
import { getConditionalUrl } from '../../../utils/cdnHelper';
import logoImage from '../../../assets/images/logo.png';
import { calculateNetPrice, calculateNetPriceFromGross23, formatPriceInt, getVatRate, recalculateGrossPrice } from '../../../utils/priceCalculations';

interface SimpleTemplateProps {
  estimateData: EstimateData;
  userInfo: UserCompany | null;
  categorySummaries: CategoryTotal[];
  totalPrice: number;
  discountedPrice: number;
  priceDisplayOption: PriceDisplayOption;
  priceType: PriceTypeOption;
  vatRate: VatRateOption;
}

export const SimpleTemplate = forwardRef<HTMLDivElement, SimpleTemplateProps>(({
  estimateData,
  userInfo,
  categorySummaries,
  totalPrice,
  discountedPrice,
  priceDisplayOption,
  priceType,
  vatRate
}, ref) => {
  const { name, description, createdAt, updatedAt, discount } = estimateData;

  // Funkcja do grupowania produktów (identyczna jak w CategorySection)
  const groupAndSumProducts = (products: Product[]) => {
    const groupedProducts: { [key: string]: Product } = {};

    products.forEach(product => {
      // Klucz grupowania dla produktów z szerokością i wysokością
      const key = product.width && product.height
        ? product.name
        : `${product.name}-${product.priceOption || ''}-${product.unit}`;

      if (!groupedProducts[key]) {
        // Kopia pierwszego produktu z grupy
        groupedProducts[key] = { ...product, quantity: 0, price: 0 };
      }

      // Aktualizacja ilości i ceny
      if (product.width && product.height) {
        const area = (product.width * product.height) / 10000; // Konwersja na m²
        groupedProducts[key].quantity += area;
        groupedProducts[key].price += product.price;

        // Aktualizacja szerokości i wysokości proporcjonalnie
        groupedProducts[key].width = Math.sqrt(groupedProducts[key].quantity * 10000);
        groupedProducts[key].height = groupedProducts[key].width;
      } else {
        groupedProducts[key].quantity += product.quantity;
        groupedProducts[key].price += product.price;
      }
    });

    return Object.values(groupedProducts);
  };

  // Obliczenia cen netto
  const vat = getVatRate(vatRate);
  const netTotalPrice = calculateNetPriceFromGross23(totalPrice);
  const netDiscountedPrice = calculateNetPriceFromGross23(discountedPrice);

  // Dodaj obliczenia cen brutto z wybraną stawką VAT:
  const grossTotalPrice = vatRate === 'vat23'
    ? totalPrice
    : recalculateGrossPrice(totalPrice, vat);
  const grossDiscountedPrice = vatRate === 'vat23'
    ? discountedPrice
    : recalculateGrossPrice(discountedPrice, vat);

  const formatQuantity = (product: CategoryTotal['products'][0]): string => {
    if (product.width && product.height) {
      const area = (product.width * product.height) / 10000;
      return `${area.toFixed(2)} m²`;
    }
    if (product.unit === 'szt') {
      return `${product.quantity.toFixed(0)} ${product.unit}`;
    }
    return `${product.quantity.toFixed(2)} ${product.unit}`;
  };

  return (
    <div className="column" ref={ref}>
      <div className="simple-template" style={{
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: '#444',
        maxWidth: '100%',
        backgroundColor: 'white',
        padding: '0',
        margin: '0 auto'
      }}>
        {/* Nagłówek */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '25px 20px',
          backgroundColor: '#f8f8f8',
          borderBottom: '1px solid #eee',
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
        }}>
          <div style={{
            maxWidth: '150px',
            marginBottom: '20px'
          }}>
            {userInfo?.company_logo ? (
              <img
                src={getConditionalUrl(userInfo.company_logo)}
                alt="Logo firmy"
                style={{ maxWidth: '100%' }}
              />
            ) : (
              <img
                src={logoImage}
                alt="GoEstima"
                style={{ maxWidth: '100%' }}
              />
            )}
          </div>
          <h1 style={{
            fontSize: '24px',
            margin: '0 0 5px 0',
            fontWeight: 'normal'
          }}>
            Wycena: <span style={{ fontWeight: 'bold' }}>{name}</span>
          </h1>
          <p style={{
            margin: '0 0 15px 0',
            color: '#777',
            fontStyle: 'italic'
          }}>
            {description}
          </p>
          <div style={{
            display: 'flex',
            fontSize: '14px',
            color: '#999'
          }}>
            <div style={{ marginRight: '15px' }}>
              Utworzona: {new Date(createdAt).toLocaleDateString()}
            </div>
            <div>
              Aktualizacja: {new Date(updatedAt).toLocaleDateString()}
            </div>
          </div>
        </div>

        {/* Podsumowanie cenowe */}
        <div style={{
          padding: '20px',
          backgroundColor: '#fafafa',
          borderBottom: '1px solid #eee',
          textAlign: 'center',
          margin: '0 auto 20px',
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '10px',
        }}>
          <div style={{
            fontSize: '16px',
            marginBottom: '20px',
            color: '#666'
          }}>
            Łączna wartość wyceny:
          </div>

          {priceType === 'gross' && (
            <div style={{
              fontSize: '28px',
              fontWeight: 'bold',
              color: '#2a5885'
            }}>
              {discount > 0 ? (
                <>
                  <span>{formatPriceInt(grossDiscountedPrice)} zł</span>
                  <span style={{
                    fontSize: '18px',
                    color: '#999',
                    textDecoration: 'line-through',
                    marginLeft: '10px'
                  }}>
                    {formatPriceInt(grossTotalPrice)} zł
                  </span>
                </>
              ) : (
                <span>{formatPriceInt(grossTotalPrice)} zł</span>
              )}
            </div>
          )}

          {priceType === 'net' && (
            <div style={{
              fontSize: '28px',
              fontWeight: 'bold',
              color: '#2a5885'
            }}>
              {discount > 0 ? (
                <>
                  <span>{formatPriceInt(netDiscountedPrice)} zł netto</span>
                  <span style={{
                    fontSize: '18px',
                    color: '#999',
                    textDecoration: 'line-through',
                    marginLeft: '10px'
                  }}>
                    {formatPriceInt(netTotalPrice)} zł netto
                  </span>
                </>
              ) : (
                <span>{formatPriceInt(netTotalPrice)} zł netto</span>
              )}
            </div>
          )}

          {priceType === 'both' && (
            <div>
              <div style={{
                fontSize: '28px',
                fontWeight: 'bold',
                color: '#2a5885',
                marginBottom: '5px'
              }}>
                {discount > 0 ? (
                  <>
                    <span>{formatPriceInt(grossDiscountedPrice)} zł brutto</span>
                    <span style={{
                      fontSize: '18px',
                      color: '#999',
                      textDecoration: 'line-through',
                      marginLeft: '10px'
                    }}>
                      {formatPriceInt(grossTotalPrice)} zł
                    </span>
                  </>
                ) : (
                  <span>{formatPriceInt(grossTotalPrice)} zł brutto</span>
                )}
              </div>
              <div style={{
                fontSize: '18px',
                color: '#888'
              }}>
                {discount > 0 ? (
                  <>
                    <span>{formatPriceInt(netDiscountedPrice)} zł netto</span>
                    <span style={{
                      fontSize: '14px',
                      color: '#999',
                      textDecoration: 'line-through',
                      marginLeft: '10px'
                    }}>
                      {formatPriceInt(netTotalPrice)} zł
                    </span>
                  </>
                ) : (
                  <span>{formatPriceInt(netTotalPrice)} zł netto</span>
                )}
              </div>
            </div>
          )}

          {discount > 0 && (
            <div style={{
              marginTop: '10px',
              color: '#e65100',
              fontSize: '16px'
            }}>
              <i className="fas fa-tag"></i> Rabat {discount}%
            </div>
          )}

          <div style={{
            marginTop: '10px',
            fontSize: '12px',
            color: '#999'
          }}>
            * Wszystkie ceny {priceType === 'net' ? 'są cenami netto' : priceType === 'both' ? `zawierają wartości netto i brutto ${vatRate === 'vat23' ? '23%' : '8%'} VAT` : `zawierają podatek VAT ${vatRate === 'vat23' ? '23%' : '8%'}`}
          </div>
        </div>

        {/* Produkty wyceny */}
        <div style={{ padding: '0 20px 20px' }}>
          {categorySummaries.map((category) => (
            <div key={category.category} style={{
              marginBottom: '25px',
              border: '1px solid #eee',
              borderRadius: '4px',
              overflow: 'hidden'
            }}>
              <div style={{
                backgroundColor: '#f5f5f5',
                padding: '10px 15px',
                borderBottom: '1px solid #eee'
              }}>
                <h3 style={{
                  margin: '0',
                  fontSize: '18px',
                  color: '#333',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <span>{category.category}</span>
                  {(priceDisplayOption === 'all' || priceDisplayOption === 'categoryOnly') && (
                    <span style={{
                      fontSize: '16px',
                      backgroundColor: '#3273dc',
                      color: 'white',
                      padding: '2px 10px',
                      borderRadius: '3px'
                    }}>
                      {priceType === 'gross' && `${formatPriceInt(vatRate === 'vat23' ? category.total : recalculateGrossPrice(category.total, vat))} zł`}
                      {priceType === 'net' && `${formatPriceInt(calculateNetPrice(category.total))} zł netto`}
                      {priceType === 'both' && `${formatPriceInt(vatRate === 'vat23' ? category.total : recalculateGrossPrice(category.total, vat))} zł / ${formatPriceInt(calculateNetPriceFromGross23(category.total))} zł netto`}
                    </span>
                  )}
                </h3>
              </div>
              <div style={{ padding: '0' }}>
                {/* Grupowanie produktów */}
                {groupAndSumProducts(category.products).map((product, prodIndex) => (
                  <div key={prodIndex} style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 15px',
                    borderBottom: prodIndex < category.products.length - 1 ? '1px solid #f0f0f0' : 'none',
                    backgroundColor: prodIndex % 2 === 0 ? '#fafafa' : '#fff'
                  }}>
                    <div style={{
                      width: '60px',
                      height: '60px',
                      backgroundColor: '#f0f0f0',
                      marginRight: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #e0e0e0'
                    }} className="product-image-container">
                      {product.imageUrl ? (
                        <img
                          src={getConditionalUrl(product.imageUrl)}
                          alt={product.name}
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      ) : (
                        <i className="fas fa-cube" style={{ fontSize: '24px', color: '#ccc' }}></i>
                      )}
                    </div>
                    <div style={{ flex: '1' }}>
                      <div style={{ fontWeight: 'bold', marginBottom: '3px' }}>
                        {product.name}
                        {product.priceOption && (
                          <span style={{
                            fontWeight: 'normal',
                            marginLeft: '5px',
                            color: '#777',
                            fontSize: '14px'
                          }}>
                            ({product.priceOption})
                          </span>
                        )}
                        {product.isDeleted && (
                          <span style={{
                            color: '#cc0000',
                            marginLeft: '10px',
                            fontSize: '14px'
                          }}>
                            <i className="fas fa-exclamation-triangle" style={{ marginRight: '5px' }}></i>
                            archiwalny
                          </span>
                        )}
                      </div>
                      <div style={{ fontSize: '14px', color: '#777' }}>
                        {formatQuantity(product)}
                      </div>
                    </div>
                    {priceDisplayOption === 'all' && (
                      <div style={{
                        minWidth: '100px',
                        textAlign: 'right',
                        fontWeight: 'bold'
                      }}>
                        {priceType === 'gross' && `${formatPriceInt(vatRate === 'vat23' ? product.price : recalculateGrossPrice(product.price, vat))} zł`}
                        {priceType === 'net' && `${formatPriceInt(calculateNetPrice(product.price))} zł`}
                        {priceType === 'both' && (
                          <div>
                            <div>{formatPriceInt(vatRate === 'vat23' ? product.price : recalculateGrossPrice(product.price, vat))} zł</div>
                            <div style={{ fontSize: '13px', color: '#777' }}>
                              {formatPriceInt(calculateNetPriceFromGross23(product.price))} zł netto
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Informacje o firmie */}
        <div style={{
          backgroundColor: '#f8f8f8',
          padding: '20px',
          borderTop: '1px solid #eee',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '14px',
          color: '#777'
        }}>
          <div>
            {userInfo?.company_name && <div style={{ marginBottom: '5px' }}>{userInfo.company_name}</div>}
            {userInfo?.phone_number && (
              <div style={{ marginBottom: '5px' }}>
                <i className="fas fa-phone" style={{ marginRight: '5px' }}></i>
                {userInfo.phone_number}
              </div>
            )}
            {userInfo?.email && (
              <div>
                <i className="fas fa-envelope" style={{ marginRight: '5px' }}></i>
                {userInfo.email}
              </div>
            )}
          </div>
          <div style={{ textAlign: 'right' }}>
            <div>Wygenerowano: {new Date().toLocaleDateString()}</div>
            <div style={{ marginTop: '5px' }}>GoEstima &copy; {new Date().getFullYear()}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

SimpleTemplate.displayName = 'SimpleTemplate';
