// components/EstimateSummary/SummaryDetails.tsx
import React from 'react';
import { CategoryTotal, PriceDisplayOption, PriceTypeOption, VatRateOption } from '../../types/EstimateSummary';
import { CategorySection } from './CategorySection';
import { calculateNetPriceFromGross23, formatPriceInt, getVatRate, recalculateGrossPrice } from '../../utils/priceCalculations';

interface SummaryDetailsProps {
  categorySummaries: CategoryTotal[];
  estimateId: number;
  totalPrice: number;
  discount: number;
  discountedPrice: number;
  priceDisplayOption: PriceDisplayOption;
  priceType: PriceTypeOption;
  vatRate: VatRateOption;
}

export const SummaryDetails: React.FC<SummaryDetailsProps> = ({
  categorySummaries,
  estimateId,
  totalPrice,
  discount,
  discountedPrice,
  priceDisplayOption,
  priceType,
  vatRate
}) => {
  const priceDifference = totalPrice - discountedPrice;

  const vat = getVatRate(vatRate);
  const netTotalPrice = calculateNetPriceFromGross23(totalPrice);
  const netDiscountedPrice = calculateNetPriceFromGross23(discountedPrice);
  const netPriceDifference = calculateNetPriceFromGross23(priceDifference);

  // Ceny brutto z wybraną stawką VAT
  const grossTotalPrice = vatRate === 'vat23'
    ? totalPrice
    : recalculateGrossPrice(totalPrice, vat);
  const grossDiscountedPrice = vatRate === 'vat23'
    ? discountedPrice
    : recalculateGrossPrice(discountedPrice, vat);
  const grossPriceDifference = vatRate === 'vat23'
    ? priceDifference
    : recalculateGrossPrice(priceDifference, vat);


  if (categorySummaries.length === 0 || categorySummaries.every(category => category.products.length === 0)) {
    return (
      <div className="summary-details has-text-centered p-5">
        <p className="is-size-6 has-text-grey-light">
          <i className="fas fa-info-circle mr-2"></i>
          Ta wycena jest pusta. Nie dodano jeszcze żadnych produktów.
        </p>
      </div>
    );
  }

  // Grupuj kategorie w pary, aby mogły być wyświetlane obok siebie z taką samą wysokością
  const filteredCategories = categorySummaries.filter(category => category.products.length > 0);
  const categoryPairs = [];
  for (let i = 0; i < filteredCategories.length; i += 2) {
    if (i + 1 < filteredCategories.length) {
      categoryPairs.push([filteredCategories[i], filteredCategories[i + 1]]);
    } else {
      categoryPairs.push([filteredCategories[i]]);
    }
  }

  return (
    <div className="summary-details" style={{ backgroundColor: '#f9f9f9' }}>
      {/* Podsumowanie ceny */}
      <div className="total-price-box p-3 mb-3" style={{ backgroundColor: '#f0f0f0', borderLeft: '4px solid #3273dc' }}>
        <p className="is-size-5 mb-0">
          <span className="icon mr-2 has-text-info">
            <i className="fas fa-calculator"></i>
          </span>
          <span className="has-text-weight-semibold">Suma całkowita: </span>
          {priceType === 'gross' && (
            <>
              {discount > 0 ? (
                <>
                  <span className="has-text-info has-text-weight-bold">{formatPriceInt(grossDiscountedPrice)} zł</span>
                  <span className="has-text-grey ml-2" style={{ textDecoration: 'line-through', fontSize: '0.9em' }}>
                    {formatPriceInt(grossTotalPrice)} zł
                  </span>
                </>
              ) : (
                <span className="has-text-info has-text-weight-bold">{formatPriceInt(grossTotalPrice)} zł</span>
              )}
            </>
          )}

          {priceType === 'net' && (
            <>
              {discount > 0 ? (
                <>
                  <span className="has-text-info has-text-weight-bold">{formatPriceInt(netDiscountedPrice)} zł</span>
                  <span className="has-text-grey ml-2" style={{ textDecoration: 'line-through', fontSize: '0.9em' }}>
                    {formatPriceInt(netTotalPrice)} zł
                  </span>
                </>
              ) : (
                <span className="has-text-info has-text-weight-bold">{formatPriceInt(netTotalPrice)} zł</span>
              )}
            </>
          )}
          {priceType === 'both' && (
            <>
              {discount > 0 ? (
                <div className="is-flex is-flex-direction-column">
                  <div>
                    <span className="has-text-info has-text-weight-bold">{formatPriceInt(grossDiscountedPrice)} zł brutto</span>
                    <span className="has-text-grey ml-2" style={{ textDecoration: 'line-through', fontSize: '0.9em' }}>
                      {formatPriceInt(grossTotalPrice)} zł
                    </span>
                  </div>
                  <div>
                    <span className="has-text-info">{formatPriceInt(netDiscountedPrice)} zł netto</span>
                    <span className="has-text-grey ml-2" style={{ textDecoration: 'line-through', fontSize: '0.9em' }}>
                      {formatPriceInt(netTotalPrice)} zł
                    </span>
                  </div>
                </div>
              ) : (
                <div className="is-flex is-flex-direction-column">
                  <span className="has-text-info has-text-weight-bold">{formatPriceInt(grossTotalPrice)} zł brutto</span>
                  <span className="has-text-info">{formatPriceInt(netTotalPrice)} zł netto</span>
                </div>
              )}
            </>
          )}
        </p>
        {discount > 0 && (
          <p className="is-size-6 has-text-danger mt-1 mb-0 ml-4">
            <i className="fas fa-tag mr-1"></i>
            Rabat {discount}% (oszczędzasz: {
              priceType === 'net'
                ? `${formatPriceInt(netPriceDifference)} zł netto`
                : priceType === 'both'
                  ? `${formatPriceInt(grossPriceDifference)} zł brutto / ${formatPriceInt(netPriceDifference)} zł netto`
                  : `${formatPriceInt(grossPriceDifference)} zł`
            })
          </p>
        )}

        {/* Informacja o VAT */}
        <p className="is-size-7 has-text-grey mt-1 mb-0 ml-4">
          * Wszystkie ceny {priceType === 'net' ? 'są cenami netto' : priceType === 'both' ? `zawierają wartości netto i brutto z ${vatRate === 'vat23' ? '23%' : '8%'} VAT` : `zawierają podatek VAT ${vatRate === 'vat23' ? '23%' : '8%'}`}
        </p>
      </div>

      {/* Kategorie produktów pogrupowane w pary, aby zachować równy układ */}
      {categoryPairs.map((pair, pairIndex) => (
        <div key={pairIndex} className="columns mb-2 is-variable is-1">
          {pair.map((category) => (
            <div key={category.category} className="column is-6">
              <CategorySection
                category={category}
                estimateId={estimateId}
                priceDisplayOption={priceDisplayOption}
                priceType={priceType}
                vatRate={vatRate}
              />
            </div>
          ))}

          {/* Jeśli w ostatniej parze jest tylko jeden element, dodajemy pusty element dla zachowania układu */}
          {pair.length === 1 && <div className="column is-6"></div>}
        </div>
      ))}
    </div>
  );
};
